import React from 'react' 
import { useLocation, useParams } from 'react-router-dom'
import Layout from '../Components/Layout'
import AddVideos from '../Components/AddAnimal/AddVideos'
import AddSpecialOffer from '../Components/AddAnimal/AddSpecialOffer'
import AddDailyAnnounce from '../Components/AddAnimal/AddDailyAnnounce'
import AddOther from '../Components/AddAnimal/AddOther'
import AddMating from '../Components/AddAnimal/AddMating'
import AddLookFor from '../Components/AddAnimal/AddLookFor'
import AddMarket from '../Components/AddAnimal/AddMarket'
import AddAuction from '../Components/AddAnimal/AddAuction'
import AddAdoption from '../Components/AddAnimal/AddAdoption'
import AddDelivery from '../Components/AddAnimal/AddDelivery'
import AddVeterinary from '../Components/AddAnimal/AddVeterinary'
import AddLostAnimal from '../Components/AddAnimal/AddLostAnimal'
import AddPhotographer from '../Components/AddAnimal/AddPhotographer'
import AddQuestion from '../Components/AddAnimal/AddQuestion'
import { useEffect } from 'react'
import AddAwaLive from '../Components/AddAnimal/AddAwaLive'
import AddRegisterAnimal from '../Components/AddAnimal/AddRegisterAnimal'
import AddSalesPages from '../Components/AddAnimal/AddSalesPages'
import BlueButton from '../Components/UI/Buttons/BlueButton'
import axios from 'axios'
import { config, url } from '../Components/Helpers/Help'
import { t } from 'i18next'
import { useState } from 'react'
import Input from '../Components/UI/Input'
import alert from '../Helpers/Modal'
import AddSubCategory from '../Components/AddAnimal/AddSubCategory'

const AddAnimals = () => {
     const param = useParams()
     useEffect(() => {
          window.scrollTo(0, 0)
     }, [param])
    
     return (
          <Layout>


             

               <main className="AddAnimals container mx-auto pt-24 md:pt-36 lg:pt-28 px-4 md:px-0 min-h-[80vh]">
                    {/* <form onSubmit={submitHandler} action="" className=''> */}
                    {param.slug === 'auctions' && <AddAuction />}
                    {param.slug === 'add-sub-categorey' && <AddSubCategory />}
                    {param.slug === 'markets' && <AddMarket />}
                    {param.slug === 'daily-announcements' && <AddDailyAnnounce />}
                    {param.slug === 'awa-lives' && <AddAwaLive />}
                    {param.slug === 'sales-pages' && <AddSalesPages />}
                    {param.slug === 'special-offers' && <AddSpecialOffer />}
                    {param.slug === 'videos' && <AddVideos />}
                    {param.slug === 'photographers' && <AddPhotographer />}
                    {param.slug === 'quarterings' && <AddAdoption />}
                    {param.slug === 'veterinaries' && <AddVeterinary />}
                    {param.slug === 'matings' && <AddMating />}
                    {param.slug === 'lost_animals' && <AddLostAnimal />}
                    {param.slug === 'deliveries' && <AddDelivery />}
                    {param.slug === 'looking-for' && <AddLookFor />}
                    {param.slug === 'questions' && <AddQuestion />}
                    {param.slug === 'others' && <AddOther />}
                    {param.slug === 'animal-register' && <AddRegisterAnimal />}
                    {/* </form> */}
               </main>
          </Layout>
     )
}

export default AddAnimals