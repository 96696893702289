import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import Male from '../assets/Icons/Male'
import Female from '../assets/Icons/Female'
import ProductSlider from '../Components/ProductDetails/ProductSlider';
import ProductMap from '../Components/ProductDetails/ProductMap'
import Seller from '../Components/ProductDetails/Seller'
import Description from '../Components/ProductDetails/Description';
import ProductTitle from '../Components/ProductDetails/ProductTitle';
import ConditionsProduct from '../Components/ProductDetails/ConditionsProduct';
import axios from 'axios';
import { config, url } from '../Components/Helpers/Help';
import { useParams } from 'react-router-dom';
import { Player } from 'video-react';
import { t } from 'i18next';
import alert from '../Helpers/Modal';
import BlueButton from '../Components/UI/Buttons/BlueButton';
import { useNavigate } from 'react-router-dom';
import Pusher from 'pusher-js';



const Details = () => {
     const [isDetails, setIsDetails] = useState();
     const param = useParams()
     const id = param.id
     const type = param.type
     const [price, setPrice] = useState();
     const [binding, setIsBinding] = useState(false);
     const [details, setDetails] = useState(false);
     const [todayMarketDetails, setTodayMarketDetails] = useState(false);
     const [market, setMarket] = useState(true);
     const [rateValue, setRateValue] = useState();
     const [rateMeModal, setRateMeModal] = useState(false);
     const navigate = useNavigate();
     const [lastPrice, setLastPrice] = useState();
     const [lastBids, setLastBids] = useState([]);

     useEffect(() => {
          axios(url + `animals/details/${type}/${id}`, config).then((response) => {
               setIsDetails(response.data.data);
               console.log(response.data.data);
          }).catch((error) => {
               console.log(error.response)
          });
          window.scrollTo(0, 0)
     }, [type, id]);


     const bindingNowHandler = () => {
          const isVip = JSON.parse(localStorage?.__User_aW__a_Info)
          if (isVip?.is_vip !== true) {
               alert({
                    title: `${t('Alert')}`,
                    text: `${t(`Sorry, You should be VIP user `)}`,
                    icon: 'error',
               })
               return
          }
          setIsBinding(true)
          if (!binding) {
               return
          }
          if (!price) { 
               alert({
                    title: `${t('Alert')}`,
                    text: `${t(`Select your price`)}`,

                    icon: 'error',
               })
               return
          }
          axios.post(url + 'animals/bb/add-bid',
               //  Data
               {
                    animal_id: id,
                    price: price,
                    slug: type,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    // setIsBinding(false)
                    setPrice('')
                    alert({
                         title: `${t('Done')}`,
                         text: `${t(`Thank you for send`)}`,
                         icon: 'success',
                    })
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }

     const marketHandler = () => {
          axios.post(url + 'animals/bb/set-auction-in-market',
               //  Data
               {
                    animal_id: id,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    setMarket(false)
                    alert({
                         title: `${t('Done')}`,
                         text: `${t(`Thank you for send`)}`,

                         icon: 'success',
                    })
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }

     const makeOfferPriceHandler = () => {
          setIsBinding(true)
          if (!binding) {
               return
          }
          if (!price) {
               alert({
                    title: `${t('Alert')}`,
                    text: `${t(`Select your price`)}`,

                    icon: 'error',
               })
               return
          }
          axios.post(url + 'animals/bb/market-offer',
               //  Data
               {
                    animal_id: id,
                    price: price,
                    slug: type,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    setMarket(false)
                    alert({
                         title: `${t('Done')}`,
                         text: `${t(`Thank you for send`)}`,

                         icon: 'success',
                    })
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }

     const goToAuctionHandler = () => {
          axios.post(url + 'animals/bb/go-to-auctions',
               //  Data
               {
                    animal_id: id,
                    slug: type,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    setMarket(false)
                    alert({
                         title: `${t('Done')}`,
                         text: `${t(`Thank you for send`)}`,

                         icon: 'success',
                    })
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }


     const goToAuctionHandlerMarket = () => {

          axios.post(url + 'animals/bb/go-to-auctions',
               //  Data
               {
                    animal_id: id,
                    slug: type,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    setMarket(false)
                    alert({
                         title: `${t('Done')}`,
                         text: `${t(`Thank you for send`)}`,

                         icon: 'success',
                    })
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }
     const acceptPriceHandlerMarket = () => {

          axios.post(url + 'animals/bb/accept-last-price',
               //  Data
               {
                    animal_id: id,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    setMarket(false)
                    alert({
                         title: `${t('Done')}`,
                         text: `${t(`Thank you for send`)}`,

                         icon: 'success',
                    })
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }


     const rateMe = (rate) => {
          axios.post(url + 'users/rate',
               //  Data
               {
                    rated_id: id,
                    rate: rateValue,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setRateMeModal(false)
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })

               })
     }


     const handleDelete = () => {
          axios.post(url + 'animals/bb/delete',
               //  Data
               {
                    id: id,
                    slug: type,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    navigate(-1, { replace: true });
                    console.log(response)
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })

               })
     }



     useEffect(() => {
          const pusher = new Pusher('4cd59efd7f1d45a34882', {
               cluster: 'ap2',
               encrypted: true,
          });

          // Subscribe to the private channel for the current user
          const channel = pusher.subscribe('awa-lives');

          // Bind to the event for new notifications
          channel.bind('live-bid-added', (data) => {
               setLastPrice(data?.data.last_price);
               console.log(data?.data.last_price)
               // Handle the new notification here, e.g., show a toast, update notification count, etc.
          });

          // Clean up the Pusher instance when the component unmounts
          return () => {
               pusher.disconnect();
          };
     }, []);

     useEffect(() => {
          const pusher = new Pusher('4cd59efd7f1d45a34882', {
               cluster: 'ap2',
               encrypted: true,
          });

          // Subscribe to the private channel for the current user
          const channel = pusher.subscribe(`auctions_${id}`);

          // Bind to the event for new notifications
          channel.bind('auction-bid-added', (data) => {
               setLastPrice(data?.data.max_price);
               setLastBids(data?.data?.bids)
               // Handle the new notification here, e.g., show a toast, update notification count, etc.
          });

          // Clean up the Pusher instance when the component unmounts
          return () => {
               pusher.disconnect();
          };
     }, []);



     return (
          <Layout>
               <main className="container mx-auto pt-24 md:pt-36 lg:pt-28 px-4 md:px-0 mt-6">

                    {
                         rateMeModal &&
                         <>
                              <div className="overlay fixed right-0 top-0 bg-black/90 h-screen w-screen z-40" onClick={() => setRateMeModal(false)} />
                              <div className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pb-[6rem] md:pb-0 shadow-xl w-8/12 md:w-[60rem] bg-[#F9F9F9]  z-50 p-4 xl:p-8 overflow-y-scroll scroll-none `}>
                                   <h3 className='border-b-2 border-blue pb-4 font-semibold text-[1.6rem]'> {t('Rate Me')} </h3>
                                   <div className='mt-4 flex flex-wrap items-center justify-between'>
                                        <div className="rate w-full flex justify-center h-[5.6rem] float-left mt-12 px-[1rem]">
                                             <input type="radio" id="star5" name="rate" value="5" onClick={(e) => setRateValue(e.target.value)} />
                                             <label for="star5" title="text">5 stars</label>
                                             <input type="radio" id="star4" name="rate" value="4" onClick={(e) => setRateValue(e.target.value)} />
                                             <label for="star4" title="text">4 stars</label>
                                             <input type="radio" id="star3" name="rate" value="3" onClick={(e) => setRateValue(e.target.value)} />
                                             <label for="star3" title="text">3 stars</label>
                                             <input type="radio" id="star2" name="rate" value="2" onClick={(e) => setRateValue(e.target.value)} />
                                             <label for="star2" title="text">2 stars</label>
                                             <input type="radio" id="star1" name="rate" value="1" onClick={(e) => setRateValue(e.target.value)} />
                                             <label for="star1" title="text">1 star</label>
                                        </div>
                                        <div className="w-full flex items-center justify-center my-8">
                                             <BlueButton onClick={rateMe} className='flex p-4 px-8 rounded-none'>{t('Send')}</BlueButton>
                                        </div>
                                   </div>
                              </div>
                         </>
                    }
                    <div className="row flex items-start justify-center flex-wrap min-h-screen">
                         <div className="w-full md:w-7/12 xl:w-8/12">
                              {isDetails &&
                                   <div className="bg-white shadow-xl rounded-3xl px-4 xl:px-6 pt-2">
                                        {(isDetails && (isDetails.weight || isDetails.gender || isDetails.age || isDetails.title_ad)) &&
                                             <ProductTitle gender={isDetails.gender} age={isDetails.age} weight={isDetails.weight} title_ad={isDetails.title_ad} />
                                        }
                                        {(isDetails && isDetails.images && isDetails.images.length > 0) &&
                                             <ProductSlider images={isDetails.images} />
                                        }
                                        {isDetails && isDetails.images?.length === 0 &&
                                             <img className='rounded-2xl w-full md:w-8/12 mb-24 mx-auto' src={isDetails.images?.length > 0 ? isDetails.images : [isDetails.image]} alt="" />
                                        }
                                        {isDetails && isDetails.slug === "delivery" &&
                                             <img className='rounded-2xl w-full md:w-8/12 mb-24 mx-auto' src={isDetails.image} alt="" />
                                        }
                                        <div className="row flex items-start justify-start flex-wrap">
                                             {isDetails && isDetails.slug !== 'auctions' && isDetails.user &&
                                                  <div className="w-full lg:w-1/2">
                                                       <Seller
                                                            name={isDetails.user.f_name + ' ' + isDetails.user.l_name}
                                                            email={isDetails.user.email}
                                                            phone={isDetails.user.phone}
                                                            badge={isDetails.user && isDetails.user.badge}
                                                            userImage={isDetails.user.personal_picture}
                                                            id={isDetails.user.id}
                                                            location={isDetails.user.city && isDetails.user.city.name}
                                                            avg_rate={isDetails.user && isDetails.user.avg_rate_int}
                                                       />
                                                  </div>}
                                             <div className="w-full lg:w-1/2">
                                                  {isDetails && isDetails.lng && <ProductMap lng={+isDetails.lng} lat={+isDetails.lat} />}
                                             </div>
                                        </div>
                                        {isDetails &&
                                             <Description
                                                  rateMe={() => setRateMeModal(true)}
                                                  id={isDetails.id}
                                                  slug={isDetails.slug}
                                                  vehicle_type={isDetails.vehicle_type}
                                                  avg_rate={isDetails.avg_rate_int}
                                                  passport_number={isDetails.passport_number}
                                                  note={isDetails.note}
                                                  color={isDetails.color}
                                                  color_name={isDetails.color_name}
                                                  address={isDetails.address}
                                                  name={isDetails.name}
                                                  date_of_birth={isDetails.date_of_birth}
                                                  lost_date={isDetails.lost_date}
                                                  lost_time={isDetails.lost_time}
                                                  start_time={isDetails.start_time}
                                                  end_time={isDetails.end_time}
                                                  start_date={isDetails.start_date}
                                                  end_date={isDetails.end_date}
                                                  post_on={isDetails.post_on}
                                                  price_range_from={isDetails.price_range_from}
                                                  price_range_to={isDetails.price_range_to}
                                                  age_range_from={isDetails.age_range_from}
                                                  age_range_to={isDetails.age_range_to}
                                                  day_off={isDetails.day_off}
                                                  post_date={isDetails.post_date}
                                                  open_at={isDetails.open_at}
                                                  close_at={isDetails.close_at}
                                                  userType={type}
                                                  type={isDetails.sub_category?.title}
                                                  number={isDetails.number}
                                                  size={isDetails.size}
                                                  species={isDetails.species}
                                                  video={isDetails.video}
                                                  phone={isDetails.slug !== 'auctions' && isDetails.phone}
                                                  email={isDetails.slug !== 'auctions' && isDetails.email}
                                                  id_front={isDetails.id_front}
                                                  id_back={isDetails.id_back}
                                                  car_registration_font={isDetails.car_registration_font}
                                                  car_registration_back={isDetails.car_registration_back}
                                                  driving_license_front={isDetails.driving_license_front}
                                                  driving_license_back={isDetails.driving_license_back}
                                                  sub_category={isDetails.category?.title}
                                                  certificates={isDetails.certificates}
                                                  gender={isDetails.gender}

                                             />
                                        }
                                        {(isDetails && isDetails.video) &&
                                             <div className="w-full mx-auto pb-12">
                                                  <h3 className='text-[1.8rem] font-bold my-2 flex items-center mt-8'>{t('Video')}:</h3>
                                                  <Player
                                                       height={360}
                                                       width={'100%'}
                                                       fluid={false}
                                                       playsInline
                                                       src={isDetails.video}
                                                  />
                                             </div>
                                        }
                                   </div>
                              }

                         </div>
                         {isDetails &&
                              <div className="w-full md:w-5/12 xl:w-4/12 px-0 md:px-4 xl:px-8 mt-8 md:mt-0">
                                   <div className="bg-white shadow-xl rounded-3xl p-4 xl:px-6">
                                        {binding && localStorage.__User_aW__a_Info && localStorage.__User_aW__a_Info != 'null' &&
                                             <div className="card">
                                                  <div className={`price flex items-center justify-start flex-wrap gap-4 transition-all overflow-hidden ${binding ? 'h-auto' : 'h-0'}`}>
                                                       <h3 className='w-full border-b mb-6 pb-6 text-center'>{t('Min offer')} = {(lastPrice ? 100 + +lastPrice : 100 + +isDetails.max_price)} {t('AED')}</h3>
                                                       <span onClick={() => setPrice(lastPrice ? 100 + +lastPrice : 100 + +isDetails.max_price)} className={`${(lastPrice ? 100 + +lastPrice === price : 100 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 100 + +lastPrice : 100 + +isDetails.max_price}</span>
                                                       <span onClick={() => setPrice(lastPrice ? 200 + +lastPrice : 200 + +isDetails.max_price)} className={`${(lastPrice ? 200 + +lastPrice === price : 200 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 200 + +lastPrice : 200 + +isDetails.max_price}</span>
                                                       <span onClick={() => setPrice(lastPrice ? 300 + +lastPrice : 300 + +isDetails.max_price)} className={`${(lastPrice ? 300 + +lastPrice === price : 300 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 300 + +lastPrice : 300 + +isDetails.max_price}</span>
                                                       <span onClick={() => setPrice(lastPrice ? 400 + +lastPrice : 400 + +isDetails.max_price)} className={`${(lastPrice ? 400 + +lastPrice === price : 400 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 400 + +lastPrice : 400 + +isDetails.max_price}</span>
                                                       <span onClick={() => setPrice(lastPrice ? 500 + +lastPrice : 500 + +isDetails.max_price)} className={`${(lastPrice ? 500 + +lastPrice === price : 500 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 500 + +lastPrice : 500 + +isDetails.max_price}</span>
                                                       <span onClick={() => setPrice(lastPrice ? 600 + +lastPrice : 600 + +isDetails.max_price)} className={`${(lastPrice ? 600 + +lastPrice === price : 600 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 600 + +lastPrice : 600 + +isDetails.max_price}</span>
                                                       <div className="w-full border border-blue py-4 px-2 rounded-lg">
                                                            <input type='number' onChange={(e) => setPrice(e.target.value)} value={price || ''} placeholder={t('enter ypur bid')} />
                                                       </div>
                                                  </div>
                                             </div>}
                                        {isDetails?.offered_prices?.length > 0 && localStorage.__User_aW__a_Info && localStorage.__User_aW__a_Info !== 'null' &&
                                             <div className={`price flex items-center justify-start flex-wrap gap-4 transition-all overflow-hidden mt-4 `}>
                                                  {
                                                       (lastBids.length < 1 && isDetails.offered_prices && isDetails.offered_prices.length > 0) ?
                                                            isDetails?.offered_prices.map(item => (
                                                                 <div key={item.id} className='bg-gray-200 text-black/80 flex items-center justify-between flex-wrap py-3 px-6 border-gray-300 shadow-lg border cursor-pointer rounded-lg w-full'>
                                                                      <span>{item.price} {t('AED')}</span>
                                                                 </div>
                                                            ))
                                                            :
                                                            lastBids.map(item => (
                                                                 <div key={item} className='bg-gray-200 text-black/80 flex items-center justify-between flex-wrap py-3 px-6 border-gray-300 shadow-lg border cursor-pointer rounded-lg w-full'>
                                                                      <span>{item} {t('AED')}</span>
                                                                 </div>
                                                            ))
                                                  }
                                             </div>
                                        }
                                        {todayMarketDetails && localStorage.__User_aW__a_Info && localStorage.__User_aW__a_Info != 'null' &&
                                             <div className={`price flex items-center justify-start flex-wrap gap-4 transition-all overflow-hidden ${todayMarketDetails ? 'h-auto' : 'h-0'}`}>
                                                  {isDetails.type && isDetails.type.title === "Today's market" &&
                                                       <>
                                                            <p className='p-4 border w-full flex justify-between mb-4 rounded-xl text-center'><span>{t('Responded to the post')}</span> <b>{isDetails.responded_post_count} </b></p>
                                                            <p className='p-4 border w-full flex justify-between mb-4 rounded-xl text-center'><span>{t('Choose go to auction')} ({isDetails.go_auction_count})</span> <b>{isDetails.go_auction_rate}%</b></p>
                                                            <p className='p-4 border w-full flex justify-between mb-4 rounded-xl text-center'><span>{t('Gave you a price')} ({isDetails.give_price})</span> <b>{isDetails.give_price_rate}%  </b> </p>

                                                       </>
                                                  }
                                                  <p className='text-center w-full'>{t('Accept Last price')} <b>{isDetails.max_price ? isDetails.max_price : 0}</b>  {isDetails.type && isDetails.type.title === "Today's market" && 'or choose go to auction'} </p>
                                                  <ul className='flex items-center justify-center mt-4 gap-4 border-b border-gray-300 pb-8 mb-8 w-full'>
                                                       <BlueButton onClick={acceptPriceHandlerMarket} className=' w-1/2 hover:opacity-70 bg-blue rounded-2xl text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all AddAnimalButton py-4 px-8'> {t('Accept')} </BlueButton>
                                                       {isDetails.type && isDetails.type.title === "Today's market" &&
                                                            <BlueButton onClick={goToAuctionHandlerMarket} className=' w-1/2 hover:opacity-70 bg-mint rounded-2xl text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all AddAnimalButton py-4 px-8'> {t('Go To Auction')} </BlueButton>
                                                       }
                                                  </ul>
                                             </div>
                                        }
                                        <ConditionsProduct
                                             in_market_till={isDetails.in_market_till}
                                             end_minutes={isDetails.end_minutes}
                                             isMine={isDetails.is_mine}
                                             in_market={isDetails.in_market}
                                             market={market}
                                             onShowDetails={() => setDetails(!details)}
                                             onMarket={marketHandler}
                                             onBindingNow={bindingNowHandler}
                                             onMakeOfferPrice={makeOfferPriceHandler}
                                             onGoToAuction={goToAuctionHandler}
                                             onMarketDetails={() => setTodayMarketDetails(!todayMarketDetails)}
                                             is_mine={isDetails.is_mine}
                                             slug={isDetails.slug}
                                             todayMarketDetails={todayMarketDetails}
                                             type={isDetails.type && isDetails.type.title}
                                             deleteItem={handleDelete}
                                             max_price={isDetails.max_price}
                                             id={isDetails.id} />
                                   </div>
                              </div>
                         }
                    </div>
               </main>
          </Layout >
     )
}

export default Details