
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import BlueButton from '../UI/Buttons/BlueButton'
import Input from '../UI/Input'
import Select from 'react-select';
import axios from 'axios';
import { config, url } from '../Helpers/Help';
import Map from '../UI/Map';
import alert from "../../Helpers/Modal";
import ButtonLoading from '../UI/ButtonLoading';

const pricesUnits = [
  { value: '1', label: localStorage.awaLang === 'en' ? 'Day' : 'يوم' },
  { value: '2', label: localStorage.awaLang === 'en' ? 'Week' : 'أسبوع' },
  { value: '3', label: localStorage.awaLang === 'en' ? 'Month' : 'شهر' },
  { value: '4', label: localStorage.awaLang === 'en' ? 'Year' : 'سنة' },
];
const AddAdoption = () => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const [location, setLocation] = useState("");
  const [singleImage, setUploadSingleImage] = useState("");
  const [uploadVideo, setUploadVideo] = useState("");
  const [images, setImages] = useState([]);
  const [price, setPrice] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [lat, setLat] = useState()
  const [lng, setLng] = useState()
  const [priceUnit, setPriceUnit] = useState("");
  const [priceValue, setPriceValue] = useState();
  const [animalSpecies, setAnimalSpecies] = useState("");
  const [animalSpeciesError, setAnimalSpeciesError] = useState(false)
  const [colorError, setColorError] = useState(false)
  const [locationError, setlocationError] = useState(false)
  const [priceError, setpriceError] = useState(false)
  const [ImagesError, setImagesError] = useState(false)
  const [videoError, setvideoError] = useState(false)
  const [singleImageError, setsingleImageError] = useState(false)
  const [phoneError, setphoneError] = useState(false)
  const [emailError, setemailError] = useState(false)
  const [mapError, setmapError] = useState(false)
  const [priceUnitError, setpriceUnitError] = useState(false)
  const [showIn, setShowIn] = useState("1")
  const [note, setNote] = useState("");
  const [noteError, setnoteError] = useState(false)



  // upload malti image 
  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    console.log(newImageUrls)

  }, [images]);

  // Storage Images 
  function onImageChange(e) {
    setImages([...e.target.files]);
  }


  //  Submit auction form 
  const submitHandler = () => {
    // if (   !phone || !email || !note || !lat || !lng) {
    //      setIsError(true)
    // }
    if (!animalSpecies) {
      setAnimalSpeciesError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!color) {
      setColorError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!price) {
      setpriceError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!images || images.length < 3) {
      setImagesError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!uploadVideo) {
      setvideoError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!priceUnit) {
      setpriceUnitError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!singleImage) {
      setsingleImageError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!phone) {
      setphoneError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!email) {
      setemailError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!lat || !lng) {
      setmapError(true)
      return
    }
    setLoading(true)
    axios.post(url + 'animals',
      //  Data
      {
        slug: 'quarterings',
        show_in: showIn,
        address: location,
        species: animalSpecies,
        color: color,
        price: price,
        price_unit: priceUnit,
        video: uploadVideo,
        image: singleImage,
        'images[]': images,
        phone: phone,
        email: email,
        lat: lat,
        lng: lng
      },
      // Configrations
      config)
      // Response
      .then(response => {
        console.log(response)
        setColor('')
        setLocation('')
        setUploadSingleImage('')
        setUploadVideo('')
        setPrice('')
        setPhone('')
        setEmail('')
        setLat('')
        setLng('')
        setImages([])
        setLoading(false)
        if (response.data.data.payment_link) {
          window.location.href = `${response.data.data.payment_link}`
        } else {
          alert({
            title: `${t('Done')}`,
            text: `${t(`Thank you for send`)}`,

            icon: 'success',
          })
        }
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        setLoading(false)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })

  }


  useEffect(() => {
    axios(url + `type/quarterings`, config)
      // Response
      .then(response => {
        console.log(response.data.data.price)
        setPriceValue(response?.data?.data?.price)
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data)
      })

  }, [])
  return (
    <>

      <div className="row flex items-start justify-start flex-wrap p-4 w-full md:w-10/12 xl:w-8/12 mx-auto bg-white rounded-xl shadow-lg mt-8">
        <div className="w-full">
          <h3 className='font-bold text-center text-blue text-[2rem] mt-6'>{t('Add quarterings')}</h3>
        </div>
        {priceValue && <h3 className="w-full flex items-center justify-center font-bold my-8 "> {t('quarterings value')} : {showIn === '1' ? `0 ${t('AED')}` : priceValue}  </h3>}

        <div className="w-full md:w-1/2 p-2 my-2 hidden">
          <Input onFocus={() => setlocationError(false)} input_className={`border border-blue ${locationError ? 'border-red' : 'border-blue'}`} div_className="w-full" value={location} onChange={(e) => setLocation(e.target.value)} type="text" placeholder={t("location")} id="location" label={t("location")} />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setAnimalSpeciesError(false)} input_className={`border-2  ${animalSpeciesError ? 'border-red' : 'border-blue'}`} div_className="w-full" value={animalSpecies} onChange={(e) => setAnimalSpecies(e.target.value)} type="text" placeholder={t("Animal Species")} id="AnimalSpecies" label={t("Animal Species")} />
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setColorError(false)} input_className={`border border-blue py-0 h-[4rem] ${colorError ? 'border-red' : 'border-blue'}`} div_className="w-full " value={color} onChange={(e) => setColor(e.target.value)} type="color" placeholder={t("Color")} id="Color" label={t("Color")} />
        </div>



        <div className="w-full md:w-1/2 p-2 my-2 flex gap-2">
          <Input onFocus={() => setpriceError(false)} input_className={`border border-blue ${priceError ? 'border-red' : 'border-blue'}`} div_className="w-2/3" value={price} onChange={(e) => setPrice(e.target.value)} type="number" placeholder={t("price")} id="price" label={t("Price")} />
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full"> {t('Price unit')} </label>
            <Select onFocus={() => setpriceUnitError(false)} className={`${priceUnitError && 'error'}`} options={pricesUnits} onChange={(e) => setPriceUnit(e.value)} />
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full">  {t('Upload one Image')}  </label>
            <div onFocus={() => setsingleImageError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16 ${singleImageError ? 'border-red' : 'border-blue'}`}>
              <input onChange={(e) => { setUploadSingleImage(e.target.files[0]); console.log(e.target.files[0]) }} accept='image/jpg, image/jpeg,image/png' className="pl-5 mt-2 opacity-60 cursor-pointer" type="file" />
              <span className="absolute left-0 top-0 h-full leading-[2.5] bg-blue text-white px-3 border-r text-center w-[11.5rem]"> {t('Upload Image')}  </span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full">  {t('Upload three Images')}   </label>
            <div onFocus={() => setImagesError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16 ${ImagesError ? 'border-red' : 'border-blue'}`}>
              <input onChange={onImageChange} multiple accept='image/jpg, image/jpeg,image/png' className="pl-5 mt-2 opacity-60 cursor-pointer" type="file" />
              <span className="absolute top-0 left-0 h-full leading-[2.5] bg-blue text-white px-4 border-r text-center w-[12rem]"> {t('Upload Image')}  </span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full">  {t('Upload Video')}   </label>
            <div onFocus={() => setvideoError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16  ${videoError ? 'border-red' : 'border-blue'}`}>
              <input onChange={(e) => setUploadVideo(e.target.files[0])} className="pl-5 mt-2 opacity-60 cursor-pointer" type="file" accept="video/mp4,video/x-m4v,video/*" />
              <span className="absolute top-0 left-0 h-full leading-[2.5] bg-blue text-white px-3 border-r text-center w-[11.5rem]">{t('Upload Video')}</span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2"> {t('Posted on')} </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t('My page')}
              <input name="PostedOn" value='MyPage' checked={showIn === "1"} onChange={() => setShowIn('1')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t('My page & AWA page')}
              <input name="PostedOn" value='AWAPage' checked={showIn === '2'} onChange={() => setShowIn('2')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>

          </div>
        </div>

        <hr className="block my-12 w-full" />
        <h3 className="w-full rtl:text-right ltr:text-left font-bold px-4">{t('Contact')}</h3>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setphoneError(false)} input_className={`border ${phoneError ? 'border-red' : 'border-blue'} `} div_className="w-full" value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" placeholder={t("Phone")} id="Phone" label={t("Phone")} />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setemailError(false)} input_className={`border ${emailError ? 'border-red' : 'border-blue'} `} div_className="w-full" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t("Email")} id="Email" label={t("Email")} />
        </div>

        <div className="w-full p-2">
          <label htmlFor="" className="w-full font-medium text-[1.5]"> {t('Note')} </label>
          <textarea onFocus={() => setnoteError(false)} className={`w-full rounded-lg relative overflow-hidden p-4 border-2 ${noteError ? 'border-red' : 'border-blue'}`} value={note} onChange={(e) => setNote(e.target.value)} name="" id="" cols="30" rows="5" placeholder={t('Note . . .')}></textarea>
        </div>

        <div onFocus={() => setmapError(false)} className={`w-full p-2  ${mapError && 'border-red border'}`}>
          <Map address={(e) => setLocation(e)} lat={(e) => setLat(e)} lng={(e) => setLng(e)} />
        </div>

        <div className="w-full flex items-center justify-center">
          <BlueButton disabled={loading} className={`text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${loading && 'cursor-not-allowed'} `} onClick={submitHandler}>
            {loading ?
              <ButtonLoading />
              :
              `${t("Send")}`
            }
          </BlueButton>
        </div>
        <p className='font-bold text-red capitalize text-center w-full mt-4'>
          {animalSpeciesError && `${t('Please enter your  animal species')}`}
          {priceUnitError && `${t('Please enter your price unit')}`}
          {colorError && `${t('Please enter your color')}`}
          {locationError && `${t('Please enter your location')}`}
          {priceError && `${t('Please enter your price')}`}
          {singleImageError && `${t('Please enter your Image')}`}
          {ImagesError && `${t('Please enter your single Image')}`}
          {videoError && `${t('Please enter your video')}`}
          {phoneError && `${t('Please enter your phone')}`}
          {emailError && `${t('Please enter your email')}`}
          {mapError && `${t('Please enter your map location')}`}
        </p>
      </div>
    </>
  )
}

export default AddAdoption