import React, { useEffect, useRef, useState } from 'react'
import BlueButton from '../UI/Buttons/BlueButton'
import InMarket from '../../Components/Lottie/InMarket.json'
import RedButton from '../UI/Buttons/RedButton';
import Lottie from 'react-lottie'
import { t } from 'i18next';
import { config, url } from '../Helpers/Help';
import axios from 'axios';
import alert from '../../Helpers/Modal';
import { Link } from 'react-router-dom';
import PreLoader from '../UI/PreLoader';
import NotItems from '../UI/NotItems';
import InfiniteScroll from 'react-infinite-scroll-component';

const defaultOptions = {
     loop: true,
     autoplay: true,
     animationData: InMarket,
     rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
     }
};

const DashboardAuction = () => {
     const [auction, setAuction] = useState([]);
     const [page, setPage] = useState(1);
     const [hasMore, setHasMore] = useState(true);
     const [isLoading, setIsLoading] = useState(false);

     useEffect(() => {
          setIsLoading(true)
          axios.get(url + `users/my-offer-prices?limit=10&offset=${page}&slug=auctions`, config).then(response => {
               setIsLoading(false)
               const data = response.data.data.data
               setAuction(data);
          });
     }, []);

     const onLoadMore = () => {
          setIsLoading(true)
          axios.get(url + `users/my-offer-prices?limit=10&offset=${page + 1}&slug=auctions`, config).then(response => {
               setIsLoading(false)
               if (response.data.data.data.length === 0) {
                    setHasMore(false);
               } else {
                    setAuction(prevItems => [...prevItems, ...response.data.data.data]);
                    setPage(prevPage => prevPage + 1);

               }
          });
     };


     const acceptHandler = (id, status) => {
          axios.post(url + 'users/my-offer-prices/status',
               //  Data
               {
                    id: id,
                    status: status
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response.data.data)
                    setAuction((prev) => {
                         return prev.filter(meetup => meetup.id !== id)
                    });
                    alert({
                         title: `${t('Thanks You')}`,
                         text: `${t('Done')}`,
                         icon: 'success',
                         timer: 2000,
                    })

               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }



     return (
          <>
               {window.innerWidth > 767 &&
                    <div className="TableHead text-center row flex items-center justify-center flex-wrap w-full bg-white p-4 rounded-lg shadow-sm">
                         <div className="photo capitalize md:w-1/5">
                              {t('photo')}
                         </div>
                         <div className="date-time capitalize md:w-1/5">
                              {t('date & time')}
                         </div>
                         <div className="price capitalize md:w-1/5">
                              {t('price')}
                         </div>
                         <div className="inMarket capitalize md:w-1/5">
                              {t('inMarket')}
                         </div>
                         <div className="auction capitalize md:w-1/5">
                              {t('auction')}
                         </div>
                    </div>
               }
               <div className="TableBody flex flex-wrap justify-center">
                    <InfiniteScroll
                         dataLength={auction.length}
                         next={onLoadMore}
                         hasMore={hasMore}
                         endMessage={<p className='mt-12 flex items-center justify-center flex-wrap'> <b> {t('You have seen it all')}</b> </p>}
                         loader={<PreLoader />}
                    >
                         {(auction && auction.length > 0) &&
                              auction.map(item => (
                                   item.object &&
                                   <div key={item.id} className='text-left md:text-center mt-12 row flex mx-auto items-center justify-start md:justify-center flex-wrap w-full sm:w-[49%] md:w-full bg-white p-4 rounded-lg shadow-sm'>
                                        <Link to={`/details/${item.object.slug}/${item.object.id}`} className="photo capitalize md:w-1/5 p-2">
                                             <img className='rounded-lg w-full' width='250' height='250' src={item.object && item.object.image} alt="" />
                                        </Link>
                                        <Link to={`/details/${item.object.slug}/${item.object.id}`} className="date-time capitalize w-1/2 md:w-1/5">
                                             {item.object && item.object.end_date} <br /> {item.object && item.object.end_time}
                                        </Link>
                                        <Link to={`/details/${item.object.slug}/${item.object.id}`} className="price capitalize w-1/2 md:w-1/5">
                                             {item.object && item.price} {t('AED')}
                                        </Link>
                                        <div className="inMarket capitalize w-1/2 md:w-1/5">
                                             <RedButton className='flex items-center justify-center p-4 px-2 my-4 mx-auto lg:w-11/12 hover:bg-red hover:text-white'>
                                                  <div className="w-12 mx-1 bg-white py-1 rounded-md">
                                                       <Lottie options={defaultOptions} height={20} width={20} />
                                                  </div> {window.innerWidth > 1024 && <>{t('In Market')}</>}
                                             </RedButton>
                                        </div>
                                        <div className="auction capitalize w-1/2 md:w-1/5">
                                             <div className="flex flex-col gap-4">
                                                  {item.accepted === '0' &&
                                                       <>
                                                            <BlueButton className={`AddAnimalButton py-4 px-8 bg-green ${item.accepted === '1' ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'}`} onClick={() => acceptHandler(item.id, 1)}>
                                                                 ✔ {item.accepted === '1' ? `${t('Accepted')}` : `${t('Accept')}`}
                                                            </BlueButton>
                                                            <BlueButton className={`AddAnimalButton py-4 px-8 bg-red ${item.accepted === '-1' ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'}`} onClick={() => acceptHandler(item.id, -1)}>
                                                                 ✗    {item.accepted === '-1' ? `${t('Rejected')}` : `${t('Reject')}`}
                                                            </BlueButton>
                                                       </>
                                                  }
                                                  {item.accepted === '1' && <>{t('Accepted')}</>}
                                                  {item.accepted === '-1' && <>{t('Rejected')}</>}
                                             </div>

                                        </div>
                                   </div>
                              ))

                         }
                    </InfiniteScroll>

                    {
                         !isLoading && auction.length === 0 &&
                         <div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
                              <NotItems />
                         </div>
                    }
               </div>

          </>
     )
}

export default DashboardAuction