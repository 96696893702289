import React from 'react'
import Jiuist from '../../../assets/Icons/Jiuist'
import BlueButton from '../Buttons/BlueButton'
import Report from '../../../assets/Icons/Report'
import Remove from '../../../assets/Icons/Remove'
import TurnOff from '../../../assets/Icons/TurnOff'
import { Link } from 'react-router-dom'

const NotificationCard = (props) => {
     const { slug, title, body, id } = props
     return (
          <Link to={`/details/${slug}/${id}`} className='w-full flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative overflow-hidden flex items-start justify-start gap-4'>
               {/* <div className="img w-12 md:w-36 h-12 md:h-36 relative overflow-hidden rounded-2xl">
                    <img className='h-full w-full object-cover' src={img} alt="" />
               </div> */}
               <div className="data w-4/6">
                    <p className='my-2 text-mint'> {title} </p>
                    <p>{body} </p>
                    {/* <div className="icon absolute h-full right-4 top-4 flex flex-col gap-4 bg-white">
                         <button className='scale-75'>
                              <Remove />
                         </button>
                         <button className='scale-75'>
                              <TurnOff />
                         </button>
                         <button className='scale-75'>
                              <Report fill='red' />
                         </button>
                    </div> */}
               </div>

          </Link>
     )
}

export default NotificationCard