import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlueButton from '../Buttons/BlueButton';
import Badge from '../../../assets/Icons/Badge';
import BadgeGray from '../../../assets/Icons/BadgeGray';
import { Link } from 'react-router-dom';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { t } from 'i18next';
import Verified from '../../../assets/Icons/Verified';

const LostAnimal = (props) => {
     const { img, type, userImage, badge, name, location, loastDate, id, userId } = props
     return (
          <div className={`AuctionCard dir-rtl mx-2 my-2  flex items-start justify-center bg-white rounded-2xl flex-wrap no-wrap min-h-[20rem]`}>
               <Link to={`/details/lost_animals/${id}`} className="w-full sm:w-1/2 h-[20rem] ">
                    <img src={img} alt="" className='h-full w-full object-cover rounded-2xl' />
               </Link>
               <div className="w-full sm:w-1/2">
                    <ul className='p-2 md:p-4'>
                         {(userImage || name) &&
                              <div className='flex items-center justify-start flex-wrap px-2'>
                                   <Link to={`/user-page/${userId}`} className="user flex items-center gap-2">
                                        {userImage && <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                                             <img src={userImage} alt="" className='h-full w-full object-cover' />
                                        </div>
                                        }
                                        {name && <Link className="name">
                                             {name}
                                             <div className=" no-wrap rtl:text-left ltr:text-right ltr:dir-ltr">
                                                  {props.rate &&
                                                       <span className="text-[1.5rem] text-yellow-500 -mt-2 flex">
                                                            {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                                                            {props.rate === '1' && '★☆☆☆☆'}
                                                            {props.rate === '2' && '★★☆☆☆'}
                                                            {props.rate === '3' && '★★★☆☆'}
                                                            {props.rate === '4' && '★★★★☆'}
                                                            {props.rate === '5' && '★★★★★'}
                                                            {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                                                       </span>}
                                             </div>
                                        </Link>
                                        }
                                        <div className="flex items-center">
                                             {badge === 'gold' ?
                                                  <Badge />
                                                  :
                                                  <BadgeGray />
                                             }
                                             {props.is_verified === 1 && <div className=""><Verified /></div>}
                                        </div>
                                   </Link>
                              </div>
                         }
                         <Link to={`/details/lost_animals/${id}`} className="w-full sm:w-1/2 h-[20rem] ">
                              {loastDate && <li className='mt-4 no-wrap'>{t('Loast Date')}: <b>{loastDate}</b></li>}
                              {location && <li className='mt-4 no-wrap'>{t('Location')}: <b>{location}</b></li>}
                              {type && <li className='mt-4 no-wrap'>{t('Type')}: <b>{type}</b></li>}
                              <BlueButton className='w-full flex items-center justify-center gap-2 p-4 hover:bg-mint mt-4'> {t('Details')}</BlueButton>
                         </Link>
                    </ul>
               </div>
          </div>
     )
}

export default LostAnimal