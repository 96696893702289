import React from 'react'
import LeftArrow from '../../assets/Icons/LeftArrow'
import { InboxApi } from '../Helpers/Data'
import InboxCard from '../UI/Cards/Inbox-Card'

const Inbox = (props) => {
     return (
          <div className={`fixed shadow-xl top-0 min-w-[50rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}>
               <h3 onClick={props.onBack} className='cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4'> <LeftArrow /> Inbox </h3>
               <ul className='text-[1.4rem] font-semibold flex items-center justify-around flex-wrap gap-2 w-[50rem]'>

                    {
                         InboxApi.map(item => (
                              <InboxCard key={item.id} img={item.img} title={item.title} user={item.user} price={item.price} />

                         ))
                    }
               </ul>
          </div>
     )
}

export default Inbox