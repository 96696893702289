import React from 'react'
import Layout from '../Components/Layout'
import cat1 from '../assets/images/cat1.png'
import tree from '../assets/images/tree.png'
import tree1 from '../assets/images/tree1.png'
import tree2 from '../assets/images/tree2.png'
import logo from '../assets/images/whiteAwa.svg'
import AccessCardComponent from '../Components/Seactions/AccessCardComponent'
import { useState } from 'react'
import { config, url } from '../Components/Helpers/Help'
import { useEffect } from 'react'
import axios from 'axios'
import { t } from 'i18next'
import alert from '../Helpers/Modal'
import { Link } from 'react-router-dom'
import Border from '../assets/Icons/Border'
const MyAccessCard = () => {
     const [singleAccessCard, setSingleAccessCard] = useState();
     useEffect(() => {
          axios(url + `users/my-card`, config).then((response) => {
               setSingleAccessCard(response.data);
               console.log(response.data);
          }).catch((error) =>
               console.log(error));
     }, []);
     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
                    <div className="row flex items-start justify-center flex-wrap">
                         {singleAccessCard &&
                              <div className="w-full sm:w-6/12 md:w-4/12 xl:w-3/12 text-center px-2">
                                   {/* <AccessCardComponent
                                        sub_title={singleAccessCard.sub_title}
                                        title={singleAccessCard.title}
                                        slug={singleAccessCard.slug}
                                        id={singleAccessCard.id}
                                        features={singleAccessCard.features}
                                        photo={singleAccessCard.icon}
                                        bordercolor={singleAccessCard.color}
                                        bgcolor={singleAccessCard.color}
                                        textcolor={singleAccessCard.color}
                                        tree={singleAccessCard.footer_img}
                                        singleCard={true}
                                        openModal={modalHandler}
                                   /> */}
                                   <div className="card relative  h-[60rem]">
                                        <img id={singleAccessCard.id} src={singleAccessCard.access_card.back} alt="" className='front h-[60rem] mt-6 relative overflow-hidden w-full text-white mb-2 mx-auto flex rounded-lg cursor-pointer' />
                                        <div className='back w-full h-full mt-6 relative p-4 text-white mb-2  rounded-lg cursor-pointer'>
                                             <img className='absolute w-full left-1/2 -translate-x-1/2 top-0 h-[60rem]' src={singleAccessCard.access_card.front} alt="" />
                                             <div className='w-full flex items-center justify-start relative top-0 h-[8rem]'>
                                                  <div className='w-1/3 '>
                                                       <Border className='absolute top-0 rtl:right-6 ltr:left-6 w-36 h-36 z-20' />
                                                       <div className="absolute top-2 rtl:right-8 ltr:left-8 AccessCardImage ">
                                                            <img className='AccessCardImage w-32 h-32 ' src={singleAccessCard.user.personal_picture} alt="" />
                                                       </div>
                                                  </div>
                                                  <div className='w-2/3 flex items-center justify-end flex-wrap'>
                                                       <div className="borderGradient h-2 w-11/12 mx-auto" />
                                                       <h3 className='w-full my-2 text-[2rem]'>{singleAccessCard.user.f_name} {singleAccessCard.user.l_name}</h3>
                                                       <div className="borderGradient h-2 w-11/12 mx-auto" />
                                                  </div>
                                             </div>
                                             <div className="w-full relative z-[10] text-center mt-4">
                                                  {/* <h3 className='mb-4'><b>{t('Title')} </b>: <span className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.access_card.title }} /> </h3>
                                                  <h3 className='mb-4'> <b>{t('Price')}</b>  : <span className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.access_card.price }} /> {t('AED')} </h3> */}
                                                  <div className='mb-4 h-[22rem] relative overflow-hidden overflow-y-auto no-scroll  px-4'>
                                                       <textarea disabled className=' w-full h-full resize-none overflow-y-auto leading-[2] bg-transparent no-scroll' name="" id="" cols="30" rows="20" value={singleAccessCard.access_card.description}></textarea>
                                                  </div>
                                             </div>
                                             <img src={'data:image/png;base64,' + singleAccessCard.qr} alt="" className='w-32 mx-auto mt-8 absolute bottom-6 left-8' />
                                             <div className='absolute bottom-12 left-1/2 -translate-x-1/2'>
                                                  <h3 className='mb-4'> <b>{t('valid untill')}</b>  </h3>
                                                  <span className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.valid_month }} />   / 
                                                   <span className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.valid_year }} /> 
                                             </div>
                                             <img src={logo} alt="" className='w-32 mx-auto mt-8 absolute bottom-12 right-8 bg-[#0f70b7] px-4 rounded-2xl' />
                                        </div>
                                   </div>

                                   {singleAccessCard.access_card.slug !== 'gold' &&
                                        <Link to={`/access-card`} className="card_data flex items-center justify-center flex-wrap mt-12 bg-blue hover:bg-mint duration-700 w-11/12 rounded-lg mx-auto py-4 px-3 text-white capitalize">
                                             {t('upgrade')}
                                        </Link>
                                   }
                              </div>
                         }
                         <div className="w-full sm:w-6/12 md:w-8/12 xl:w-9/12 rtl:text-right ltr:text-left px-2 md:px-6 mt-12 md:mt-0">
                              {singleAccessCard &&
                                   <>
                                        {/* <h1 className='w-full my-8 text-[3.2rem] font-bold'>{singleAccessCard.title}</h1> */}
                                        <div className="bg-white shadow rounded-2xl p-8 my-8">
                                             <h3 className='mb-4'><b>{t('Title')} </b>: <span className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.access_card.title }} /> </h3>
                                             <h3 className='mb-4'> <b>{t('Price')}</b>  : <span className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.access_card.price }} /> {t('AED')} </h3>
                                             {/* <div className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.access_card.description }} /> */}
                                             <h3 className='mb-4'><b>{t('Description')} </b>:  </h3>
                                             <textarea disabled className='w-full h-full resize-none overflow-y-hidden leading-[2] bg-transparent' name="" id="" cols="30" rows="20" value={singleAccessCard.access_card.description}></textarea>
                                        </div>
                                   </>
                              }
                         </div>

                    </div>
               </main>
          </Layout>
     )
}

export default MyAccessCard