import React from 'react'
import { Link } from 'react-router-dom'
import Badge from '../../assets/Icons/Badge'
import BadgeGray from '../../assets/Icons/BadgeGray'
import { t } from 'i18next'

const Seller = (props) => {
     const { id, badge, userImage, avg_rate, name, location, email, phone } = props
     return (
          <>
               <h3 className='text-[1.8rem] font-bold my-2 flex items-center mt:12'>{t('SELLER INFORMATION')}</h3>
               <Link to={`/user-page/${id}`} className="w-full md:mt-16 flex items-center gap-2">
                    <div className="userImage w-20 h-20 rounded-full relative overflow-hidden">
                         <img src={userImage} alt="" className='h-full w-full object-cover' />
                    </div>
                    <div className="name">
                         <h3 className='text-[1.6rem] font-semibold flex items-center gap-4'> {name}  {badge === 'gray' ? <BadgeGray /> : <Badge />} </h3>
                         {avg_rate &&
                              <ul className='-my-3'>
                                   <li>
                                        <span className="text-[2rem] text-yellow-500 rtl:ml-4 ltr:mr-4">
                                             {avg_rate === '1' && '★☆☆☆☆'}
                                             {avg_rate === '2' && '★★☆☆☆'}
                                             {avg_rate === '3' && '★★★☆☆'}
                                             {avg_rate === '4' && '★★★★☆'}
                                             {avg_rate === '5' && '★★★★★'}
                                             {(avg_rate === '0' || !avg_rate) && '☆☆☆☆☆'}  </span>
                                   </li>
                              </ul>
                         }
                         <p className='text-blue cursor-pointer font-medium text-[1.2rem]'>{t('View Profile')}</p>
                    </div>
               </Link>
               <ul className="w-full userData mb-12 leading-[2] font-semibold ml-20">

                    <li className='capitalize'>{name}</li>
                    <li className='capitalize'>{location} </li>
                    <a className='w-full block' href='mailto:info@awasell.co'>{email}</a>
                    <a className='w-full block' href={`tel:${phone}`}>{phone}</a>
               </ul>
          </>
     )
}

export default Seller