import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import HelpSupportIcon from '../assets/Icons/Settings/HelpSupport'
import { t } from 'i18next'

const data = [
     {
          "id": 1,
          "question": "What is a Awa ?",
          "answer": "A transfer fee is payable if you transfer your property to someone else. It is an administrative charge levied by the primary developer."
     },
     {
          "id": 2,
          "question": "Can I Sell on Awa",
          "answer": "You can get a two-year visa when you purchase a property in Dubai that is:\r\n\r\nCompleted and handed over to you.\r\nWorth a minimum value of one million dirhams.\r\n\r\nNote 1 :\r\n\r\nThis one million does not include the closing cost. You can read about the closing cost and other fees of buying a property in Dubai in this article.\r\n\r\nNote 2 :\r\n\r\nIf you decide to get a visa after few months from buying the property, then the purchase price will not be considered. You should conduct a property valuation, and the result of this valuation should be AED 1,000,000 or more to be eligible to get the visa. The valuation considers few factors e.g, the value of the last 10 sales transactions within last 3 years, the current market property listing prices, the condition of the property, and others…\r\n\r\nWith the new long-term visa, for investors who invest in property value of AED 5 million or more, a 5-year visa is granted. The renewable 10 year-long visa is for investors setting up start-ups, businesses or branches of their companies of valued AED 10 million or more as long as non-real estate investments are not less than 60% of the total investment. It also applies to spouses and children, with conditions. The UAE announced a new law that provides a 5-year residency visa for retired expatriates with the possibility of renewal for those who wish to stay longer.\r\n\r\nExpatriates over 55 years of age are eligible if they have an investment in a property worth AED 2 million, have a minimum of AED 1M in savings, and an active income of at least AED 20,000 per month."
     }
]


const HelpSupport = () => {
     const [id, setID] = useState()


     return (
          <Layout>
               <main className="container mx-auto pt-24 md:pt-28 px-4 md:px-0 flex items-start justify-center flex-wrap">
                    <h3 className='w-full flex items-center justify-start gap-4 font-bold text-[1.4rem] md:text-[2.4rem] md:mt-28'> <HelpSupportIcon /> {t('Help & Support')} </h3>
                    <section className="md:mt-12 w-full row flex items-start justify-center flex-wrap">
                         {
                              data &&
                              <>
                                   {
                                        data.map((item, index) => (
                                             <div className="w-full border border-lightGray bg-gold rounded-lg mt-4 cursor-pointer" onClick={() => setID(item.id)} key={index}>
                                                  <div className="bg-blue text-white flex items-center justify-between w-full p-8">
                                                       <h1 className="font-medium text-white text-2xl">{item.question}</h1>
                                                       {id == item.id ?
                                                            <span className="text-gray-400 bg-light rounded-full">
                                                                 <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 12H6" />
                                                                 </svg>
                                                            </span>
                                                            :
                                                            <span className=" bg-light rounded-full">
                                                                 <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                 </svg>
                                                            </span>
                                                       }
                                                  </div>

                                                  <div className={`rounded-b-lg mt-4 p-4 ${id === item.id ? 'flex ltr:text-left rtl:text-right' : 'hidden'}`}>
                                                       <p className="p-2 text-md leading-[2] text-black text-[1.6rem]">{item.answer}</p>
                                                  </div>
                                             </div>
                                        ))
                                   }
                              </>
                         }
                    </section>
               </main>
          </Layout>
     )
}

export default HelpSupport