import axios from 'axios'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import LeftArrow from '../../assets/Icons/LeftArrow'
import { NotificationApi } from '../Helpers/Data'
import { config, url } from '../Helpers/Help'
import NotificationCard from '../UI/Cards/Notification-Card'

const Notification = (props) => {
     const [noti, setNoti] = useState([])

     useEffect(() => {
          axios.get(url + `users/notifications?limit=100&offset=1`, config)
               .then((response) => {
                    console.log(response.data.data.data)
                    setNoti(response.data.data.data)
               })
               .catch((error) => {
                    console.log(error);
               });
     }, [])


     return (
          <div className={`fixed shadow-xl pt-16 md:pt-24 top-0 w-full md:w-[50rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}>
               <h3 onClick={props.onBack} className='dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4'> <LeftArrow /> {t('Notification')} </h3>
               <ul className='text-[1.4rem] font-semibold flex items-center justify-around flex-wrap gap-2 w-full'>

                    {
                         noti.map((item, index) => (
                              <NotificationCard key={index} slug={item.data && item.data.slug} id={item.data && item.data.id} title={item.title} body={item.body} price={item.data && item.data.price} />

                         ))
                    }
               </ul>
          </div>
     )
}

export default Notification