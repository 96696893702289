import React, { useState } from 'react';
import { GoogleMap, LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { t } from "i18next";

const containerStyle = {
     width: '100%',
     height: '400px'
};
const key = "AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc";

const lib = ['places'];

const MAP_OPTIONS = {
     scrollwheel: true,
}
const Map = (props) => {
     const [lat, setLat] = useState([])
     const [lng, setLng] = useState([])
     const [mapref, setMapRef] = useState(null);
     const [defaultLocation, setDefaultLocation] = useState({ lat: 25.270012722064614, lng: 55.258894247149705 });
     const [zoomSize, setZoomSize] = useState(8);
     const [searchBox, setSearchBox] = useState(null);
     const [pickLocation, setPickLocation] = useState('');

     const onPlacesChanged = () => {
          var lk = searchBox.getPlaces();
          setZoomSize(15)
          setDefaultLocation({ lat: lk[0].geometry.location.lat(), lng: lk[0].geometry.location.lng() });
          setPickLocation(lk[0].formatted_address)
          setLat(lk[0].geometry.location.lat());
          setLng(lk[0].geometry.location.lng());
          props.lat(lk[0].geometry.location.lat())
          props.lng(lk[0].geometry.location.lng())
     }
     const onSBLoad = ref => {
          setSearchBox(ref);
     };

     const handleOnLoad = map => {
          setMapRef(map);
     };
     const handleCenterChanged = (e) => {
          if (mapref) {
               const newCenter = mapref.getCenter();
               setLat(newCenter.lat());
               setLng(newCenter.lng());
               displayLocation(newCenter.lat(), newCenter.lng())
               document.getElementById("searchMap").value = '';
               props.lat(newCenter.lat())
               props.lng(newCenter.lng())

          }
     };
     const displayLocation = (latitude, longitude) => {
          var request = new XMLHttpRequest();
          var method = 'GET';
          var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&sensor=true&key=AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc';
          var async = true;

          request.open(method, url, async);
          request.onreadystatechange = function () {
               if (request.readyState == 4 && request.status == 200) {

                    var data = JSON.parse(request.responseText);
                    var address = data.results[0];
                    props.address(address.formatted_address)
                    console.log(address.formatted_address)
               }
          };
          request.send();
     };
     return (
          <LoadScript googleMapsApiKey={key} libraries={lib}>
               <div className='relative '>
                    <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Map_pin_icon.svg/1200px-Map_pin_icon.svg.png'} className="absolute top-1/2 left-1/2 -translate-x-1/2 w-12 z-40" alt="" />
                    <GoogleMap center={defaultLocation} zoom={zoomSize} onLoad={handleOnLoad} onDragEnd={handleCenterChanged} options={MAP_OPTIONS} mapContainerStyle={{ width: '100%', height: '40rem' }} >
                         <StandaloneSearchBox onLoad={onSBLoad} onPlacesChanged={onPlacesChanged} >
                              <input id="searchMap" type="text" placeholder={`${t('Choose your location')}`} style={{ boxSizing: `border-box`, border: `1px solid #ccc`, width: `100%`, padding: `20px`, paddingLeft: `5px`, borderRadius: `0`, boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, fontSize: `14px`, outline: `none`, textOverflow: `ellipses`, position: "absolute", left: "0%", top: "0rem", }} />
                         </StandaloneSearchBox>
                    </GoogleMap>
               </div>
          </LoadScript>
     )
}

export default Map