import { t } from 'i18next'
import React from 'react'

const Description = (props) => {
     const { id, start_time, end_time, start_date, end_date, age_range_from, age_range_to, rateMe, price_range_from, price_range_to, userType, day_off, color_name, avg_rate, gender, vehicle_type, driving_license_back, car_registration_font, car_registration_back, id_front, id_back, name, certificates, email, phone, open_at, close_at, sub_category, species, lost_time, lost_date, type, post_on, post_date, size, date_of_birth, number, address, color, passport_number, note } = props

     return (
          <>
               <div className="flex items-start flex-col">
                    <h3 className='text-[1.8rem] font-bold my-2 flex items-center mt-16 capitalize'>{t('description')}</h3>
                    <ul className="w-full md:w-1/2 userData mb-12 leading-[2]">
                         {userType === 'deliveries' &&
                              <li><b className='ltr:mr-2 rtl:ml-2'>{t('Rate')}:</b>
                                   <span className="text-[2rem] text-yellow-500 ltr:ml-4 rtl:mr-4"> {avg_rate === '1' && '★☆☆☆☆'} {avg_rate === '2' && '★★☆☆☆'} {avg_rate === '3' && '★★★☆☆'} {avg_rate === '4' && '★★★★☆'} {avg_rate === '5' && '★★★★★'} {(avg_rate === '0' || !avg_rate) && '☆☆☆☆☆'}  </span>
                                   <button onClick={rateMe} className="text-blue" title="rate me" alt='rate me'>✎</button>
                              </li>
                         }
                         {name && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Name')}:</b> {name} </li>}
                         {type && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Category')}:</b> {type} </li>}
                         {sub_category && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Type')}:</b> {sub_category} </li>}
                         {id && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Code Number')}:</b> {id} </li>}
                         {species && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Species')}:</b>{species}</li>}
                         {date_of_birth && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Date of Birth')}:</b> {date_of_birth} </li>}
                         {number && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Number')}:</b> {number} </li>}
                         {color && <li className='flex items-center'><b className='ltr:mr-2 rtl:ml-2'> Color:</b> <div className={`w-12 h-12 flex mx-6 rounded-lg ${color === '#ffffff' && 'border'}`} style={{ background: color }} /> {color_name} </li>}
                         {vehicle_type && <li><b className='ltr:mr-2 rtl:ml-2'>{t('vehicle type')}:</b> {vehicle_type}</li>}
                         {gender && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Gender')}:</b> {gender}</li>}
                         {(price_range_from || price_range_to) && <li><b className='ltr:mr-2 rtl:ml-2'>{t('price range')}:</b> {price_range_from} {t('AED')} :  {price_range_to} {t('AED')}  </li>}
                         {(age_range_from || age_range_to) && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Age')}:</b> {age_range_from} - {age_range_to}   </li>}
                         {passport_number && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Passport')}:</b> {passport_number}</li>}
                         {size && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Size')}:</b> {size}</li>}
                         {address && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Location')}:</b> {address}</li>}
                         {start_time && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Start time')}:</b> {start_time}</li>}
                         {end_time && <li><b className='ltr:mr-2 rtl:ml-2'>{t('End time')}:</b>{end_time}</li>}
                         {start_date && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Start date')}:</b> {start_date}</li>}
                         {end_date && <li><b className='ltr:mr-2 rtl:ml-2'> {t('End date')}:</b>{end_date}</li>}
                         {post_on && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Post on')}:</b> {post_on}</li>}
                         {post_date && <li><b className='ltr:mr-2 rtl:ml-2'>{t('Post Date')}:</b>{post_date}</li>}
                         {lost_date && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Lost Date')}:</b> {lost_date}</li>}
                         {lost_time && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Lost Time')}:</b>{lost_time}</li>}
                         {open_at && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Open at')}:</b> {open_at}</li>}
                         {close_at && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Close at')}:</b>{close_at}</li>}
                         {day_off && <li><b className='ltr:mr-2 rtl:ml-2'> {t('Day off')}:</b> {day_off}</li>}
                         {phone && <li><a href={`tel:${phone}`}><b className='ltr:mr-2 rtl:ml-2'> {t('Phone')}:</b>{phone}</a></li>}
                         {email && <li><a href={`mailto:${email}`}><b className='ltr:mr-2 rtl:ml-2'> {t('Email')}:</b>{email}</a></li>}
                    </ul>

                    {/* <div className="row flex items-start justify-start flex-wrap">
                         {id_front && <div className="w-1/2 p-2 md:p-4">
                              <h3 className='text-[1.8rem] font-bold my-2 flex items-center uppercase w-full'>{t('id front')}</h3>
                              <img src={id_front} alt="" />
                         </div>
                         }
                         {id_back && <div className="w-1/2 p-2 md:p-4">
                              <h3 className='text-[1.8rem] font-bold my-2 flex items-center uppercase w-full'>{t('id back')}</h3>
                              <img src={id_back} alt="" />
                         </div>
                         }
                         {car_registration_font && <div className="w-1/2 p-2 md:p-4">
                              <h3 className='text-[1.8rem] font-bold my-2 flex items-center uppercase w-full'>{t('car registration font')}</h3>
                              <img src={car_registration_font} alt="" />
                         </div>
                         }
                         {car_registration_back && <div className="w-1/2 p-2 md:p-4">
                              <h3 className='text-[1.8rem] font-bold my-2 flex items-center uppercase w-full'>{t('car registration back')}</h3>
                              <img src={car_registration_back} alt="" />
                         </div>
                         }
                         {driving_license_front && <div className="w-1/2 p-2 md:p-4">
                              <h3 className='text-[1.8rem] font-bold my-2 flex items-center uppercase w-full'>{t('driving license front')}</h3>
                              <img src={driving_license_front} alt="" />
                         </div>
                         }
                         {driving_license_back &&
                              <div className="w-1/2 p-2 md:p-4">
                                   <h3 className='text-[1.8rem] font-bold my-2 flex items-center uppercase w-full'>{t('driving license back')}</h3>
                                   <img src={driving_license_back} alt="" />
                              </div>
                         } 
                    </div> */}
                    {certificates &&
                         <div className="row flex items-start justify-start flex-wrap">
                              <h3 className='text-[1.8rem] font-bold my-2 flex items-center uppercase w-full'>{t('certificates')}</h3>
                              {

                                   certificates.map((item, index) => (
                                        <div className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-2 md:p-4" key={index}>
                                             <img src={item} alt="" />
                                        </div>
                                   ))
                              }
                         </div>
                    }
               </div>
               {note && <div className="flex items-center justify-start flex-wrap">
                    <h3 className='text-[1.8rem] font-bold my-2 flex items-center'>{t('Note')}:</h3>
                    <p className="w-full userData mb-12 leading-[2]">
                         {note}
                    </p>
               </div>}
          </>
     )
}

export default Description