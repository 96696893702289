import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import VideoCard from '../UI/Cards/Video-card'
import SectionTitle from '../UI/Titles/SectionTitle';
import { videosSlider } from '../Helpers/SlidersOptions';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const Videos = (props) => {
     return (
          <LazyLoadComponent delaytime='500'>
               <SectionTitle to={props.to} number={props.number}>
                    {props.title}
               </SectionTitle>
               <Slider {...videosSlider}>
                    {
                         props.vipAuctionData.map((item, index) => (
                              <VideoCard  views_count={item.views_count} 
                                   live={props.live}
                                   liveLink={props.liveLink}
                                   slug={item.slug}
                                   key={item.id}
                                   id={item.id}
                                   likes_count={item.likes_count}
                                   comment_count={item.comment_count}
                                   description={item.content}
                                   poster={item.thumbnail}
                                   userImage={item.user && item.user.personal_picture}
                                   name={(item.user && item.user.f_name) && item.user.f_name}
                                   video={item.file}
                                   badge={item.user && item.user.badge}
                                   userId={item.user && item.user.id}
                                   rate={item.user && item.user.avg_rate_int}
                                   is_verified={item.user && item.user.is_verified}
                                   location={(item.user && item.user.city) && item.user.city.name} />

                         ))
                    }
               </Slider>
          </LazyLoadComponent>
     )
}

export default Videos