import React, { useEffect, useState } from "react";
import Hour from "../../assets/Icons/24Hour";
import DailyAnnounce from "../../assets/Icons/DailyAnnounce";
import Edit from "../../assets/Icons/Edit";
import Fridaymarket from "../../assets/Icons/Fridaymarket";
import Holesalemarket from "../../assets/Icons/Holesalemarket";
import Lookfor from "../../assets/Icons/Lookfor";
import Mating from "../../assets/Icons/Mating";
import Other from "../../assets/Icons/Other";
import PublicAuction from "../../assets/Icons/PublicAuction";
import Quickmarket from "../../assets/Icons/Quickmarket";
import Registration from "../../assets/Icons/Registration";
import Scan from "../../assets/Icons/Scan";
import Access from "../../assets/Icons/Access";
import Specialoffer from "../../assets/Icons/Specialoffer";
import Videos from "../../assets/Icons/Videos";
import Vip2 from "../../assets/Icons/Vip2";
import { userImage } from "../Helpers/Data";
import RegistrationMenu from "./RegistrationMenu";
import InboxIcon from "../../assets/Icons/Activities/Inbox";
import Bids from "../../assets/Icons/Activities/Bids";
import Ads from "../../assets/Icons/Activities/Ads";
import Gallery from "../../assets/Icons/Activities/Gallery";
import Favorites from "../../assets/Icons/Activities/Favorites";
import MyOffersPrice from "../../assets/Icons/Activities/MyOffersPrice";
import { Link, useNavigate } from "react-router-dom";
import Settings from "../../assets/Icons/Settings/Settings";
import Advirtisings from "../../assets/Icons/Settings/Advirtisings";
import NotificationIcon from "../../assets/Icons/Settings/Notification";
import HelpSupport from "../../assets/Icons/Settings/HelpSupport";
import TermCondition from "../../assets/Icons/Settings/TermCondition";
import Logout from "../../assets/Icons/Settings/Logout";
import AccountInformation from "./AccountInformation";
import Setting from "./Setting";
import Inbox from "./Inbox";
import Notification from "./Notification";
import { config, url } from "../Helpers/Help";
import axios from "axios";
import LeftArrow from "../../assets/Icons/LeftArrow";
import ManageOffer from "../../assets/Icons/ManageOffer";
import Report from "../../assets/Icons/Report";
import { t } from "i18next";
import HomeIcon from "../../assets/Icons/HomeIcon";
import Remind from "../../assets/Icons/Remind";
import UserIcon from "../../assets/Icons/UserIcon";
import Interested from "../../assets/Icons/Interested";
import alert from "../../Helpers/Modal";

const Menu = (props) => {
  const [profileMenu, setProfileMenu] = useState(true);
  const [setting, setSetting] = useState(false);
  const [registration, setRegistration] = useState(false);
  const [accountInformation, setAccountInformation] = useState(false);
  const [inbox, setInbox] = useState(false);
  const [notification, setNotification] = useState(false);
  const [f_name, setFname] = useState("");
  const [l_name, setLname] = useState("");
  const [upload, setUpload] = useState();
  const [nickname, setNickname] = useState();
  const [userId, setUserId] = useState();
  const [notificationCount, setNotificationCount] = useState();
  const [start, setStart] = useState();
  const [accessCardSlug, setAccessCardSlug] = useState();
  const [qr, setQr] = useState('');
  const [isQr, setIsQr] = useState(false);
  const [qrCode, setQrCode] = useState('');


  useEffect(() => {
    axios(url + `users/unread-notifications`, config).then((response) => {
      setNotificationCount(response.data.data.count);
      console.log(response.data.data.count);
    });
  }, []);

  useEffect(() => {
    axios(url + "start ", config).then((response) => {
      setStart(response.data.data);
      console.log(response.data.data.animal_types)
      localStorage.setItem(
        "__data_aW__a_start",
        JSON.stringify(response.data.data)
      );
    });
  }, []);
  useEffect(() => {
    if (localStorage.__User_aW__a_Info && localStorage.__User_aW__a_Info !== null) {
      const userData = localStorage.getItem("__User_aW__a_Info");
      const userInfo = JSON.parse(userData);
      setUpload(userInfo.personal_picture);
      setQrCode(userInfo.qr)
    }
  }, []);

  const handleRegistration = () => {
    setRegistration(true);
    setProfileMenu(false);
  };

  const closeRegistration = () => {
    setRegistration(false);
    setProfileMenu(true);
  };

  const handleBack = () => {
    setInbox(false);
    setSetting(false);
    setProfileMenu(true);
    setRegistration(false);
    setNotification(false);
    setAccountInformation(false);
  };

  const handleSetting = () => {
    setProfileMenu(false);
    setRegistration(false);
    setSetting(true);
  };
  const handleAccountInformation = () => {
    setProfileMenu(false);
    setAccountInformation(true);
  };
  const handleInbox = () => {
    setProfileMenu(false);
    setInbox(true);
  };

  const handleNotification = () => {
    setProfileMenu(false);
    setNotification(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  useEffect(() => {
    if (localStorage.__User_aW__a_Info) {
      const userData = localStorage.getItem("__User_aW__a_Info");
      const userInfo = JSON.parse(userData);
      setFname(userInfo.f_name);
      setLname(userInfo.l_name);
      setNickname(userInfo.nickname);
      setUserId(userInfo.id)
      setAccessCardSlug(userInfo.access_slug)

    }
  }, []);

  const handleScanQR = () => {
    props.onClick()
    axios.post(url + 'scan-qr',
      //  Data
      {
        qr: qr,
      },
      // Configrations
      config)
      // Response
      .then(response => {
        console.log(response)
        setQr('')
        setProfileMenu(false);
        alert({
          title: `${t('Thanks for scan QR number')}`,
          text: `${t('Done')}`,
          icon: 'success',
          timer: 2000
        })

      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })

  }


  return (
    <>
      <div className="overlay fixed right-0 top-0 bg-black/40 h-screen w-screen z-40" onClick={props.onClick} />
      {profileMenu && (
        <div className={`fixed shadow-xl md:w-[50rem] pb-[6rem] md:pb-0 pt-24 md:pt-0 top-0 w-full overflow-x-hidden bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`} >
          <div className="relative">
            <h3 onClick={props.onClick} className='dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] flex items-center justify-start gap-4'> <LeftArrow /> {t('Back')} </h3>
            <button className="flex items-center justify-between w-full">
              <Link to={`/user-page/${userId}`} className="user flex items-center gap-2">
                <div className="flex items-center justify-center w-24 h-24 rounded-full relative overflow-hidden border-blue border-2 p-2">
                  <img src={upload} alt="" className="w-20 h-20 rounded-full object-cover" />
                </div>
                <div className="text-left">
                  {nickname && <h3 className="font-bold rtl:text-right"> {nickname} </h3>}
                  <span className="text-blue cursor-pointer text-[1.4rem]"> {t('See Your Page')}  </span>
                </div>
              </Link>
              <button onClick={handleAccountInformation}>
                <Edit />
              </button>
            </button>
            <ul className="text-[1.3rem] font-semibold flex items-center justify-around flex-wrap gap-2 w-full">
              <Link to='/dashboard' className="w-[24%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-center gap-4">
                <div className="flex justify-center flex-col text-center py-4 w-full">
                  <ManageOffer className='mx-auto mb-4' /> {t('Manage Offer')}
                </div>
              </Link>

              <li onClick={handleRegistration} className="w-[24%] cursor-pointer flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-center gap-4"   >
                <div className="flex justify-center flex-col text-center py-4 w-full">
                  <Registration className='mx-auto mb-4' /> {t('Registration')}
                </div>
              </li>
              <li onClick={() => setIsQr(!isQr)} className="w-[24%] cursor-pointer flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-center gap-4"   >
                <div className="flex justify-center flex-col text-center py-4 w-full">
                  <Scan className='mx-auto mb-4' /> {t('Scan QR')}
                </div>
              </li>
              <Link to={`/my-access-card`} className="w-[24%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-center gap-4">
                <div className="flex justify-center flex-col text-center py-4 w-full">
                  <Access className='mx-auto mb-4' /> {t('Access Card')}
                </div>
              </Link>
            </ul>

            <div className={`form-group mt-6 overflow-hidden relative transition-all duration-700 ${isQr ? 'h-[100%]' : 'h-0'}`}>
              <label htmlFor="" className=" font-bold text-[1.6rem]">{t('QR Code')}</label>
              <div className="relative overflow-hidden">
                <input type="text" onChange={(e) => setQr(e.target.value)} placeholder={t('Enter your QR Code')} className="py-4 px-2 border-blue border-2 mt-2 w-full rounded-lg" />
                <button onClick={handleScanQR} className="absolute top-2 right-0 rounded-lg bg-blue px-8 py-5 text-white">{t('Send')}</button>
              </div>
              {qrCode &&
                <div className="relative">
                  <img src={`data:image/png;base64, ${qrCode}`} alt="Red dot" className="w-48 mt-8" />
                </div>
              }
            </div>
            <h3 className="border-b-2 border-blue pb-4 font-semibold text-[1.6rem] mt-8 mb-4"> {t('Your Activities')} </h3>
            <ul className="text-[1.4rem] font-semibold flex items-center justify-start flex-wrap gap-2 w-full">
              <Link to="/my-bids" onClick={props.onClick} className="w-[47%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-start gap-4" >
                <Bids /> {t('My Bids')}
                {/* <span className="absolute w-8 h-8 rounded-full right-4 top-4 bg-blue flex items-center justify-center text-white">
                1
              </span> */}
              </Link>
              <Link to="/my-offers-price" onClick={props.onClick} className="w-[47%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-start gap-4" >
                <MyOffersPrice /> {t('My Offers Price')}
                {/* <span className="absolute w-8 h-8 rounded-full right-4 top-4 bg-blue flex items-center justify-center text-white">
                1
              </span> */}
              </Link>
              <Link to="/favorite" onClick={props.onClick} className="w-[47%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-start gap-4" >
                <Favorites /> {t('My Favorites')}
              </Link>
              <Link to="/gallery" onClick={props.onClick} className="w-[47%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-start gap-4" >
                <Gallery /> {t('My Gallery')}
              </Link>
              <Link to="/my-ads" className="w-[47%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-start gap-4">
                <Ads /> {t('My Ads')}
              </Link>
              {/* <li onClick={handleInbox} className="cursor-pointer w-[47%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-start gap-4" >
                <InboxIcon /> {t('Inbox')}
              </li> */}
              {/* <Link to="/my-interested" onClick={props.onClick} className="w-[47%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-start gap-4" >
                <Interested /> {t('Interested')}
              </Link> */}
              <Link to="/frindes-ads" onClick={props.onClick} className="w-[47%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-start justify-start gap-4" >
                <Ads /> {t('Frindes Ads')}
              </Link>
            </ul>
            <h3 className="border-b-2 border-blue pb-4 font-semibold text-[1.6rem] mt-8 mb-4"> {t('Your Shortcut')}  </h3>
            <ul className="text-[1.2rem] font-semibold flex items-center justify-around flex-wrap gap-2 w-full">
              {start &&
                start.animal_types &&
                start.animal_types.map((item, index) => (
                  <Link
                    key={index}
                    to={`${item.target_page === "add_question"
                      ? `/profile/question`
                      : `${item.target_page === "add_video"
                        ? `/profile/videos`
                        : `${item.target_page === "add_animal"
                          ? `/profile/animals-type/${item.slug}`
                          : `${item.target_page === "add_image"
                            ? `/profile/animals-type/${item.slug}`
                            : ""
                          }`
                        }`
                      }`
                      }`}
                    onClick={props.onClick} className={` w-[32%] flex-col p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4`}>
                    <img src={item.icon} alt="" className="w-12" /> {item.title}
                    <span className="absolute w-8 h-8 rounded-full right-4 top-4 bg-blue flex items-center justify-center text-white">
                      {item.count}
                    </span>
                  </Link>
                ))}
            </ul>
            <h3 className="border-b-2 border-blue pb-4 font-semibold text-[1.6rem] mt-8 mb-4"> {t('Settings')}  </h3>
            <ul className="text-[1.4rem] font-semibold flex items-center justify-around flex-wrap gap-2 w-full">
              <button onClick={handleSetting} className="w-full flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4" >
                <Settings /> {t('Settings')}
              </button>
              <Link onClick={props.onClick} to="/advirtisings" className="w-full flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4" >
                <Advirtisings fill='#FE6D73' /> {t('Advirtisings')}
              </Link>
              <Link onClick={handleNotification} className="w-full flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4" >
                <div className="relative">
                  <span className="w-8 h-8 rounded-full bg-red text-white absolute top-1/2 -translate-y-1/2 text-center">{notificationCount}</span>
                  <NotificationIcon />
                </div> {t('Notification')}
              </Link>
              <Link onClick={props.onClick} to="/help-support" className="w-full flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4" >
                <HelpSupport /> {t('Help & Support')}
              </Link>
              <Link onClick={props.onClick} to="/reports" className="w-full flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4" >
                <Report fill='#D00202' /> {t('Reports')}
              </Link>
              <Link onClick={props.onClick} to="/term-condition" className="w-full flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4" >
                <TermCondition /> {t('Term & Condition')}
              </Link>
            </ul>
            <h3 className="border-b-2 border-blue pb-4 font-semibold text-[1.6rem] mt-8 mb-4"> {t('Log Out')}  </h3>
            <ul className="text-[1.4rem] font-semibold flex items-center justify-around flex-wrap gap-2 w-full">
              <button onClick={handleLogout} className="w-full flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4 text-red" >
                <Logout /> {t('Log Out')}
              </button>
            </ul>
          </div>
        </div>
      )}

      {registration && (
        <RegistrationMenu
          onClick={closeRegistration}
          onClose={props.onClick}
          className={`${registration
            ? "right-0 transition-all duration-1000"
            : "-right-[150rem] transition-all duration-1000"
            }`}
        />
      )}

      {setting && (
        <Setting
          onBack={handleBack}
          className={`${setting
            ? "right-0 transition-all duration-1000"
            : "-right-[150rem] transition-all duration-1000"
            }`}
        />
      )}

      {accountInformation && (
        <AccountInformation
          onBack={handleBack}
          className={`${accountInformation
            ? "right-0 transition-all duration-1000"
            : "-right-[150rem] transition-all duration-1000"
            }`}
        />
      )}

      {inbox && (
        <Inbox
          onBack={handleBack}
          className={`${inbox
            ? "right-0 transition-all duration-1000"
            : "-right-[150rem] transition-all duration-1000"
            }`}
        />
      )}

      {notification && (
        <Notification
          onBack={handleBack}
          className={`${notification
            ? "right-0 transition-all duration-1000"
            : "-right-[150rem] transition-all duration-1000"
            }`}
        />
      )}
    </>
  );
};

export default Menu;
