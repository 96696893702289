
import { t } from 'i18next'
import React, { useState } from 'react'
import BlueButton from '../UI/Buttons/BlueButton'
import Input from '../UI/Input'
import Title from './Title'
import ButtonLoading from '../UI/ButtonLoading'
import { config, url } from '../Helpers/Help'
import axios from 'axios'
import Map from '../UI/Map'
import alert from "../../Helpers/Modal";
import { useEffect } from 'react'
 
const days = [
  { value: 'sun', label: localStorage.awaLang === 'en' ? 'Sunday' : 'الأحد' },
  { value: 'mon', label: localStorage.awaLang === 'en' ? 'Monday' : 'الأثنين' },
  { value: 'tue', label: localStorage.awaLang === 'en' ? 'Tuesday' : 'الثلاثاء' },
  { value: 'wed', label: localStorage.awaLang === 'en' ? 'Wednesday' : 'الأربعاء' },
  { value: 'thu', label: localStorage.awaLang === 'en' ? 'Thursday' : 'الخميس' },
  { value: 'fri', label: localStorage.awaLang === 'en' ? 'Friday' : 'الجمعه' },
  { value: 'sat', label: localStorage.awaLang === 'en' ? 'Saturday' : 'السبت' }, 
];
const AddVeterinary = () => {
  const [VeterinaryName, setVeterinaryName] = useState('')
  const [location, setLocation] = useState("");
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [profileImage, setProfileImage] = useState('')
  const [images, setImages] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [daySelected, setDaySelected] = useState([])
  const [loading, setLoading] = useState(false);
  const [uploadVideo, setUploadVideo] = useState("");
  const [lat, setLat] = useState()
  const [lng, setLng] = useState()
  const [showIn, setShowIn] = useState("1")
  const [priceValue, setPriceValue] = useState();



  const [ImagesError, setImagesError] = useState(false);
  const [videoError, setvideoError] = useState(false)
  const [mapError, setmapError] = useState(false)
  const [locationError, setlocationError] = useState(false)
  const [CertificateImagesError, setCertificateImagesError] = useState(false)
  const [phoneError, setphoneError] = useState(false)
  const [emailError, setemailError] = useState(false)
  const [VeterinaryNameError, setVeterinaryNameError] = useState(false)
  const [startTimeError, setstartTimeError] = useState(false)
  const [endTimeError, setendTimeError] = useState(false)
  const [profileImageError, setprofileImageError] = useState(false)

  const handleChecked = (val) => {
    let prev = daySelected;
    let itemIndex = prev.indexOf(val);
    if (itemIndex !== -1) { prev.splice(itemIndex, 1) }
    else { prev.push(val); }
    console.log(daySelected)
    setDaySelected([...prev]);
  };

  // Storage Images 
  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  // Storage Certificate Images
  function onCertificateChange(e) {
    setCertificate([...e.target.files]);
  }

  const daysString = JSON.stringify(daySelected)
  //  Submit auction form 
  const submitHandler = () => {

    if (!VeterinaryName) {
      setVeterinaryNameError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!location) {
      setlocationError(true)
      window.scrollTo(0, 0)
      return
    }

    if (!startTime) {
      setstartTimeError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!endTime) {
      setendTimeError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!profileImage) {
      setprofileImageError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!images) {
      setImagesError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!certificate) {
      setCertificateImagesError(true)
      window.scrollTo(0, 0)
      return
    }

    if (!uploadVideo) {
      setvideoError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!phone) {
      setphoneError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!email) {
      setemailError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!lat || !lng) {
      setmapError(true)
      return
    }
    setLoading(true)
    axios.post(url + 'animals',
      //  Data
      {
        slug: 'veterinaries',
        show_in: showIn,
        name: VeterinaryName,
        profile_image: profileImage,
        images: images,
        certificates: certificate,
        video: uploadVideo,
        phone: phone,
        email: email,
        address: location,
        lat: lat,
        lng: lng,
        open_at: startTime,
        close_at: endTime,
        day_off: daysString,
      },
      // Configrations
      config)
      // Response
      .then(response => {
        console.log(response)
        setVeterinaryName("")
        setLocation("")
        setStartTime("")
        setEndTime("")
        setProfileImage("")
        setImages([])
        setCertificate([])
        setPhone("")
        setEmail("")
        setDaySelected([])
        setLoading("")
        setUploadVideo("")
        setLat("")
        setLng("")
        if (response.data.data.payment_link) {
          window.location.href = `${response.data.data.payment_link}`
        } else {
          alert({
            title: `${t('Done')}`,
            text: `${t(`Thank you for send`)}`,

            icon: 'success',
          })
        }
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        setLoading(false)
        setLoading(false)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })

  }

  useEffect(() => {
    axios(url + `type/veterinaries`, config)
      // Response
      .then(response => {
        console.log(response.data.data.price)
        setPriceValue(response?.data?.data?.price)
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data)
      })

  }, [])

  return (
    <>
      <div className="row flex items-start justify-start flex-wrap p-4 w-full md:w-10/12 xl:w-8/12 mx-auto bg-white rounded-xl shadow-lg mt-8">
        <div className="w-full">
          <Title className="mb-2"> {t('Add Veterinary')}</Title>
        </div>
        {priceValue && <h3 className="w-full flex items-center justify-center font-bold my-8 "> {t('Veterinary value')} : {showIn === '1' ? `0 ${t('AED')}` : priceValue}  </h3>}
        <hr className="block my-12 w-full" />
        <h3 className="w-full  rtl:text-right ltr:text-left font-bold px-4">{t('details')}</h3>
        <div className="w-full md:w-1/2 p-2">
          <Input onFocus={() => setVeterinaryNameError(false)} input_className={`border-2 ${VeterinaryNameError ? 'border-red' : 'border-blue'} `} div_className='w-full' value={VeterinaryName} onChange={(e) => setVeterinaryName(e.target.value)} type='text' placeholder={t('Veterinary Name')} id='VeterinaryName' label={t('Veterinary Name')} />
        </div>
        <div className="w-full md:w-1/2 p-2 hidden">
          <Input onFocus={() => setlocationError(false)} input_className={`border-2 border-blue ${locationError ? 'border-red' : 'border-blue'}`} div_className="w-full" value={location} onChange={(e) => setLocation(e.target.value)} type="text" placeholder={t("location")} id="location" label={t("location")} />
        </div>
        <div className="w-full md:w-1/2 p-2">
          <Input onFocus={() => setstartTimeError(false)} input_className={`border-2 ${startTimeError ? 'border-red' : 'border-blue'} `} div_className='w-full' value={startTime} onChange={(e) => setStartTime(e.target.value)} type='time' placeholder={t('Open At')} id='name' label={t('Open At')} />
        </div>
        <div className="w-full md:w-1/2 p-2">
          <Input onFocus={() => setendTimeError(false)} input_className={`border-2 ${endTimeError ? 'border-red' : 'border-blue'} `} div_className='w-full' value={endTime} onChange={(e) => setEndTime(e.target.value)} type='time' placeholder={t('Close At')} id='name' label={t('Close At')} />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full"> {t('Profile Image')} </label>
            <div onFocus={() => setprofileImageError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16 ${profileImageError ? 'border-red' : 'border-blue'}`}>
              <input onChange={(e) => setProfileImage(e.target.files[0])} className="pl-5 mt-2 opacity-60 cursor-pointer" type="file" />
              <span className="absolute left-0 h-full leading-[2.5] bg-blue text-white px-2 border-r text-center w-[12.5rem]"> {t('Upload Image')}  </span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full">  {t('Upload three Images')}   </label>
            <div onFocus={() => setImagesError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16 ${ImagesError ? 'border-red' : 'border-blue'}`}>
              <input onChange={onImageChange} multiple accept='image/jpg, image/jpeg,image/png' className="pl-5 mt-2 opacity-60 cursor-pointer" type="file" />
              <span className="absolute top-0 rtl:right-0 ltr:left-0 h-full leading-[2.5] bg-blue text-white px-4 border-r text-center w-[12.5rem]"> {t('Upload Image')}  </span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full"> {t('Business License')} </label>
            <div onFocus={() => setCertificateImagesError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16 ${CertificateImagesError ? 'border-red' : 'border-blue'}`}>
              <input onChange={onCertificateChange} multiple accept='application/pdf, image/jpg, image/jpeg,image/png' className="pl-5 mt-2 opacity-60 cursor-pointer" type="file" />
              <span className="absolute top-0 rtl:right-0 ltr:left-0 h-full leading-[2.5] bg-blue text-white px-4 border-r text-center w-[12.5rem]"> {t('Upload Image')}  </span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full">  {t('Upload Video')}   </label>
            <div onFocus={() => setvideoError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16  ${videoError ? 'border-red' : 'border-blue'}`}>
              <input onChange={(e) => setUploadVideo(e.target.files[0])} accept="video/mp4,video/x-m4v,video/*" className="pl-5 mt-2 opacity-60 cursor-pointer" type="file" />
              <span className="absolute top-0 rtl:right-0 ltr:left-0 h-full leading-[2.5] bg-blue text-white px-3 border-r text-center w-[12.5rem]">{t('Upload Video')}</span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2"> {t('Posted on')} </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t('My page')}
              <input name="PostedOn" value='MyPage' checked={showIn === "1"} onChange={() => setShowIn('1')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t('My page & AWA page')}
              <input name="PostedOn" value='AWAPage' checked={showIn === '2'} onChange={() => setShowIn('2')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>

          </div>
        </div>
        <div className="w-full p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2"> {t('Day off')} </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            {
              days?.map(item => (
                <label key={item.value} className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
                  {item.label}
                  <input name="Sun" value={item.value} onChange={(e) => { handleChecked(e.target.value); }} type="checkbox" className='absolute opacity-0 cursor-pointer h-0 w-0' />
                  <span className={`checkmark absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300  ${daySelected.includes(item.value) ? `bg-blue` : ''}`}></span>
                </label>
              ))
            }
          </div>
        </div>
        <hr className="block my-12 w-full" />
        <h3 className="w-full rtl:text-right ltr:text-left font-bold px-4">{t('Contact')}</h3>
        <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setphoneError(false)} input_className={`border-2 ${phoneError ? 'border-red' : 'border-blue'} `} div_className="w-full" value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" placeholder={t("Phone")} id="Phone" label={t("Phone")} />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setemailError(false)} input_className={`border-2 ${emailError ? 'border-red' : 'border-blue'} `} div_className="w-full" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t("Email")} id="Email" label={t("Email")} />
        </div>
        <div onFocus={() => setmapError(false)} className={`w-full p-2  ${mapError && 'border-red border'}`}>
          <Map address={(e) => setLocation(e)} lat={(e) => setLat(e)} lng={(e) => setLng(e)} />
        </div>
        <div className="w-full flex items-center justify-center">
          <BlueButton disabled={loading} className={`text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${loading && 'cursor-not-allowed'}`} onClick={submitHandler}>
            {loading ?
              <ButtonLoading />
              :
              `${t("Send")}`
            }
          </BlueButton>
        </div>
        <div className="my-8 w-full flex items-end justify-center">
          <p className='font-bold text-red capitalize'>
            {/* {TypeError && `${t('Please enter your category type')}`} */}
            {locationError && `${t('Please enter your location')}`}
            {ImagesError && `${t('Please enter your single Image')}`}
            {videoError && `${t('Please enter your video')}`}
            {phoneError && `${t('Please enter your phone')}`}
            {emailError && `${t('Please enter your email')}`}
            {mapError && `${t('Please enter your map location')}`}
          </p>
        </div>
      </div>
    </>
  )
}

export default AddVeterinary