import React, { useCallback, useRef, useState } from "react";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleLoginButton } from "react-social-login-buttons";
import axios from "axios";
import { t } from "i18next";
import { config, url } from "../Helpers/Help";
const REDIRECT_URI = "https://backend.awasell.co/api/v1/oauth/google/callback";
const SocialMedia = () => {
     const googleRef = useRef();
     const [provider, setProvider] = useState("");
     const [profile, setProfile] = useState();
     const onLoginStart = useCallback(() => { }, []);

     const onLogoutFailure = useCallback(() => {
          alert("logout fail");
     }, []);

     const onLogoutSuccess = useCallback(() => {
          setProfile(null);
          setProvider("");
     }, []);

     return (
          <LoginSocialGoogle ref={googleRef} 
          client_id="895560981383-k53mn0mbs0nuabtv7te2lbpa6gf77ile.apps.googleusercontent.com" 
          onLogoutFailure={onLogoutFailure} 
          onLoginStart={onLoginStart} 
          onLogoutSuccess={onLogoutSuccess}
           redirect_uri={REDIRECT_URI} 
           scope='https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.email'
               onResolve={({ provider, data }) => {
                    setProvider(provider);
                    setProfile(data, provider);
                    console.log(data);
                    axios.post(url + 'auth/social-login',
                         {
                              'token': data.access_token,
                              'unique_id': data.id,
                              'email': data.email,
                              'medium': 'google'
                         }, config)
                         .then(response => {
                              console.log(response)
                         }).catch(error => {
                              console.log(error)
                         })
               }}
               onReject={(err) => { console.log(err); }} >
               <GoogleLoginButton text={t('continue with Google')} className="googleBtn flex items-center justify-center w-full text-center" style={{ display: 'flex!important' }} />
          </LoginSocialGoogle>
     );
};

export default SocialMedia;
