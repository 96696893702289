import axios from 'axios'
import { t } from 'i18next'
import React, { useRef, useState } from 'react'
import BlueButton from '../UI/Buttons/BlueButton'
import Input from '../UI/Input'
import { url, config } from '../Helpers/Help'
import alert from '../../Helpers/Modal'
import ButtonLoading from '../UI/ButtonLoading'
import { useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import UploadIcons from '../../assets/Icons/UploadIcons';
 

const AdvirtisingsForm = (props) => {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [f_name, setFname] = useState('')
  const [l_name, setLname] = useState('')
  const [title, setTitle] = useState('')
  const [advertisingBudget, setAdvertisingBudget] = useState('1')
  const [banner, setBanner] = useState()
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([])
  const [childs, setChilds] = useState([])
  const [type, setType] = useState("");
  const [bannerPlace, setBannerPlace] = useState("");


  const [emailError, setemailError] = useState(false);
  const [phoneError, setphoneError] = useState(false);
  const [f_nameError, setf_nameError] = useState(false);
  const [l_nameError, setl_nameError] = useState(false);
  const [titleError, settitleError] = useState(false);
  const [advertisingBudgetError, setadvertisingBudgetError] = useState(false);
  const [bannerError, setbannerError] = useState(false);
  const [CategoryError, setCategoryError] = useState(false)
  const [TypeError, setTypeError] = useState(false)
  const [bannerPlaceError, setBannerPlaceError] = useState(false);

  const [tokeAlert, setTokenAlert] = useState(false)


  const SITE_KEY = '6Le4Hg4mAAAAABw3q1GrrSnBUro-ghX2HDG5jeJa';
  const captchaRef = useRef(null);



  // Get Categories from localStorage
  useEffect(() => {
    if (localStorage.__authtk__awasell_secure) {
      const data = localStorage.getItem('__data_aW__a_start')
      const dataArr = JSON.parse(data)
      setCategories(dataArr.categories)
    }
  }, [])

  // Get Childes of Categories by ID
  useEffect(() => {
    if (category) {
      const item = categories.find(item => item.id === category);
      setChilds(item.childs)
    }

  }, [category, categories])

  const submitHandler = async (e) => {
    e.preventDefault()
    let tokens = captchaRef.current.getValue();
    captchaRef.current.reset();
    console.log(tokens)

    if (!f_name) {
      setf_nameError(true)
      return
    }
    if (!l_name) {
      setl_nameError(true)
      return
    }
    if (!email) {
      setemailError(true)
      return
    }
    if (!phone) {
      setphoneError(true)
      return
    }

    if (!title) {
      settitleError(true)
      return
    }
    if (!advertisingBudget) {
      setadvertisingBudgetError(true)
      return
    }

    if (!bannerPlace) {
      setBannerPlaceError(true)
      return
    }

    if (!category && advertisingBudget !== '1') {
      setCategoryError(true)
      return
    }
    if (!type && advertisingBudget !== '1') {
      setTypeError(true)
      return
    }
    if (!banner) {
      setbannerError(true)
      return
    }
    if (tokens) {
      setTokenAlert(false)
      console.log(tokens)
    } else {
      setTokenAlert(true)
      console.log(tokens)
      return
    }

    setLoading(true)
    axios.post(url + 'advertise',
      //  Data
      {
        f_name: f_name,
        l_name: l_name,
        email: email,
        phone: phone,
        title: title,
        place: bannerPlaceError,
        banner: banner,
        budget: advertisingBudget,
        category_id: category,
        sub_category_id: type,
      },
      // Configrations
      config)
      // Response
      .then(response => {
        setEmail('')
        setPhone('')
        setFname('')
        setLname('')
        setTitle('')
        setCategory('')
        setType('')
        setBanner('')
        setAdvertisingBudget('')
        setLoading(false)
        alert({
          title: `${t('Alert')}`,
          text: `${t('Thanks for sharing your ads')}`,
          icon: 'success',
          timer: 3500
        })
      })
      // Check Error
      .catch(error => {
        setLoading(false)
        alert({
          title: `${t('Alert')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 3500
        })
      })
  }

  return (
    <>

      <div className='mt-4 flex flex-wrap items-start justify-start bg-white p-6 rounded-lg shadow-md'>
        <Input onFocus={() => setf_nameError(false)} input_className={` ${f_nameError && 'border-red'}`} div_className='my-8 text-left' value={f_name} onChange={(e) => setFname(e.target.value)} type='text' placeholder={t('First name')} id='f_name' label={t('First name')} />
        <Input onFocus={() => setl_nameError(false)} input_className={` ${l_nameError && 'border-red'}`} div_className='my-8 text-left' value={l_name} onChange={(e) => setLname(e.target.value)} type='text' placeholder={t('Last name')} id='l_name' label={t('Last name')} />
        <Input onFocus={() => setemailError(false)} input_className={` ${emailError && 'border-red'}`} div_className='my-8 text-left' value={email} onChange={(e) => setEmail(e.target.value)} type='email' placeholder={t('Your Email')} id='Email' label={t('Email address')} />
        <Input onFocus={() => setphoneError(false)} input_className={` ${phoneError && 'border-red'}`} div_className='my-8 text-left' value={phone} onChange={(e) => setPhone(e.target.value)} type='tel' placeholder={t('Your  Phone')} id='Phone' label={t('Phone')} />
        <Input onFocus={() => settitleError(false)} input_className={` ${titleError && 'border-red'}`} div_className='my-8 text-left' value={title} onChange={(e) => setTitle(e.target.value)} type='text' placeholder={t('Title')} id='Title' label={t('Title*')} />
        <div className='form-group w-full mb-4 relative my-8 text-left'>
          <label className='font-medium text-[1.5] w-full flex rtl:text-right'>{t('Advertising Budget*')}</label>
          <select onFocus={() => setadvertisingBudgetError(false)} value={advertisingBudget} onChange={(e) => { setAdvertisingBudget(e.target.value); setChilds([]) }} name="" id="" className={`bg-transparent w-full text-[1.5rem] rounded-lg border-2 border-gold py-3 px-5  ${advertisingBudgetError && 'border-red'}`}>
            <option>{t('select')}</option>
            {
              props.option.map(item => (
                <option key={item.id} value={item.id}>{item.title}</option>
              ))
            }
          </select>
        </div>
        <div className='form-group w-full mb-4 relative my-8 text-left'>
          <label className='font-medium text-[1.5] w-full flex rtl:text-right'>{t('Banner Place*')}</label>
          <select onFocus={() => setBannerPlaceError(false)} value={bannerPlace} onChange={(e) => { setBannerPlace(e.target.value); setChilds([]) }} name="" id="" className={`bg-transparent w-full text-[1.5rem] rounded-lg border-2 border-gold py-3 px-5  ${bannerPlaceError && 'border-red'}`}>
            <option>{t('select')}</option>
            {
              props.places.map(item => (
                <option key={item.id} value={item.id}>{item.place_name + ' ' } { ' ' + item.price + ' AED '}</option>
              ))
            }
          </select>
        </div>
        {
          advertisingBudget !== '1' &&
          <>
            <div className="w-full p-2 my-2">
              <div className="flex flex-col">
                <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full"> {t('Category')}</label>
                <select onFocus={() => setCategoryError(false)} onChange={(e) => { setCategory(e.target.value) }} className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${CategoryError ? 'border-red' : 'border-gray-100'}`}>
                  <option value={''}> {t('chosse')}. . . </option>
                  {
                    categories &&
                    categories.map(item => (
                      <option value={item.id} key={item.id}> {item.title} </option>
                    ))
                  }
                </select>
              </div>
            </div>

            {
              childs && childs.length > 0 &&
              <div className="w-full p-2 my-2">
                <div className="flex flex-col">
                  <label htmlFor="" className="font-medium text-[1.5] "> {t('Type')} </label>
                  <select onFocus={() => setTypeError(false)} onChange={(e) => { setType(e.target.value) }} className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${TypeError ? 'border-red' : 'border-gray-100'}`}>
                    <option value={''}> {t('chosse')}. . . </option>

                    {
                      childs &&
                      childs.map(item => (
                        <option value={item.id} key={item.id}> {item.title} </option>
                      ))
                    }
                  </select>
                </div>
              </div>
            }
          </>
        }

        <p className={`font-medium text-[1.5] mt-6`}>{t('Banner')}</p>
        <label className={`dir-ltr-ar custom-file-upload bg-transparent w-full text-[1.5rem] rounded-lg border-2 border-gold py-3 px-5 relative overflow-hidden cursor-pointer ${bannerError && ' border-red'}`}>
          <input onFocus={() => setbannerError(false)} type="file" className={`ltr:pl-16 rtl:pr-16`} onChange={(e) => setBanner(e.target.files[0])} />
          <span className='bg-white absolute top-0 left-0 h-full flex items-center px-4 rtl:w-[10rem] ltr:w-[15rem]'>{t('Custom Upload')}</span>
          <div className="absolute right-4 top-4">
            <UploadIcons />
          </div>
        </label>
        <div className="mt-6">
          <ReCAPTCHA
            className="recaptcha"
            sitekey={SITE_KEY}
            ref={captchaRef}
          />
        </div>
        {tokeAlert && <span className='text-red'>{t('Please double check on reCAPTCH')}</span>}

        <BlueButton onClick={submitHandler} className={`w-full text-[1.5rem] p-5 mt-8 ${loading && 'cursor-not-allowed'}`} disabled={loading}>
          {loading ? <ButtonLoading /> : `${t("Submit")}`}
        </BlueButton>
      </div>
    </>

  )
}

export default AdvirtisingsForm