import React from 'react'
import Layout from '../Components/Layout'
import { useState } from 'react'
import { config, url } from '../Components/Helpers/Help'
import { useEffect } from 'react'
import axios from 'axios'
import { t } from 'i18next'
import alert from '../Helpers/Modal'
const SingleAccessCard = () => {
     const [singleAccessCard, setSingleAccessCard] = useState();
     var pathname = window.location.pathname;
     var appSlug = pathname.split("/")[2];
     useEffect(() => {
          axios(url + `access-card/${appSlug}`, config).then((response) => {
               setSingleAccessCard(response.data.data);
               console.log(response.data.data);
          });
     }, []);


     const handlePayment = () => {
          axios(url + `n-genius/redirect?id=${singleAccessCard.id}&type=access_card`, config)
               .then((response) => {
                    console.log(response.data.data.paymentLink);
                    window.location.href = `${response.data.data.paymentLink}`
               })
               .catch((error) => {
                    console.log(error)
                    alert({
                         title: `${t('Payment gateway not ready')}`,
                         text: `${t(`thank you`)}`,
                         icon: 'success',
                    })
               });
     }

     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
                    <div className="row flex items-start justify-center flex-wrap">
                         {singleAccessCard &&
                              <div className="w-full sm:w-6/12 md:w-4/12 xl:w-3/12 text-center px-2 relative">
                                   {/* <AccessCardComponent
                                        sub_title={singleAccessCard.sub_title}
                                        title={singleAccessCard.title}
                                        slug={singleAccessCard.slug}
                                        id={singleAccessCard.id}
                                        features={singleAccessCard.features}
                                        photo={singleAccessCard.icon}
                                        bordercolor={singleAccessCard.color}
                                        bgcolor={singleAccessCard.color}
                                        textcolor={singleAccessCard.color}
                                        tree={singleAccessCard.footer_img}
                                        singleCard={true}
                                        openModal={modalHandler}
                                   /> */}
                                   <button onClick={handlePayment} className="card_data flex items-center justify-start flex-wrap bg-white absolute bottom-12 left-1/2 -translate-x-1/2 h-24 w-8/12 rounded-2xl opacity-0" />
                                   <img id={singleAccessCard.id} src={singleAccessCard.book} alt="" className='my-8' />


                              </div>
                         }
                         <div className="w-full sm:w-6/12 md:w-8/12 xl:w-9/12 rtl:text-right ltr:text-left px-2 md:px-6">
                              {singleAccessCard &&
                                   <>
                                        {/* <h1 className='w-full my-8 text-[3.2rem] font-bold'>{singleAccessCard.title}</h1> */}
                                        <div className="bg-white shadow rounded-2xl p-8 my-8">
                                             <h3><b>{t('title')} </b>: <span className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.title }} /> </h3>
                                             <h3> <b>{t('price')}</b>  : <span className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.price }} /> {t('AED')} </h3>
                                             <div className='reachText' dangerouslySetInnerHTML={{ __html: singleAccessCard.description }} />
                                        </div>
                                   </>
                              }
                         </div>

                    </div>
               </main>
          </Layout>
     )
}

export default SingleAccessCard